.termServices-Bg {
  padding: 80px 250px;
}

.termServices-heading h1 {
  color: var(--Black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 60px;
  text-align: center;
}

.termServices-content p {
  color: var(--color-main-black, #1A1A1A);
  text-align: justify;
  font-family: "Montserrat" !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  padding: 20px 0px;
}


.termServices-terms ul li {
  color: var(--Black, #222);
  /* Body Text */
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-left: 20px;
}

.termServices-text {
  padding: 30px 0px;
}

.termServices-text h2 {
  color: #1A1A1A;
  text-align: justify;
  font-family: "Montserrat" !important;
  font-size: 25px;
  font-style: normal;
  font-weight: 900;
}

.termServices-text p {
  color: #1A1A1A;
  font-family: "Montserrat" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 0px;
}

.termServices-text ul li {
  color: var(--Black, #222);
  /* Body Text */
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-left: 20px;
}