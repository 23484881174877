@media (min-width: 767px) {
  .ship-list-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ship-list-content h1 {
    color: var(--black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.88px;
  }

  .ship-list-content p {
    width: 526px;
    color: var(--black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }

  .ship-list-topIcon {
    position: absolute;
    right: 88px;
  }

  .ship-list-bottomIcon {
    position: absolute;
    right: 122px;
    bottom: 73px;
    z-index: -9999;
  }
}

.amenities-boat-heading {
  margin: 100px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.amenities-boat-heading-topIcon {
  position: absolute;
  right: 356px;
  top: -5px;
}

.amenities-boat-heading-bottomIcon {
  position: absolute;
  right: 385px;
  bottom: 50px;
  z-index: -9999;
}

.amenities-boat-heading-bottomIcon img {
  width: 160px;
}

/* .weeklyTrip-type-topIcon {
  position: absolute;
  right: 330px;
}

.weeklyTrip-type-bottomIcon {
  position: absolute;
  right: 363px;
  bottom: 37px;
  z-index: -9999;
} */

.amenities-boat-heading h1 {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.88px;
  text-align: center;
  margin-bottom: 10px;
}

.amenities-boat-heading p {
  color: var(--black, #050f15);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* width: 770px; */
}

.ship-list-content {
  margin: 70px 0px;
  position: relative;
}

.ship-list-button button {
  padding: 12px 28px;
  background: var(--brand, #45aaf0);
  font-weight: bold;
  margin-right: 10px;
  outline: none;
}

.shiplist-card-location p span {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  opacity: 0.8;
}

.shipList-ranking {
  display: flex;
  justify-content: space-between;
}

.shiplist-card-heading h1 {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.shiplist-card-deatil-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.shiplist-card-deatil-button h3 {
  color: var(--secand, #8bc53e);
  font-family: "Montserrat" !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.56px;
}

.shiplist-card-deatil-button h3 span {
  color: rgba(5, 15, 21, 0.5);
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.32px;
}

.shiplist-card-deatil-button button {
  padding: 12px 24px;
  font-weight: bold;
  margin-right: 10px;
  color: #fff;
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  outline: none;
  border: none;
}

.shiplist-card-discription {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.shiplist-card-discription p {
  font-family: "Montserrat" !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.7;
}

.boat-card-offer h4 {
  position: absolute;
  top: 10px;
  left: 20px;
  color: #fff;
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  background: #8bc53e;
  padding: 4px 10px;
  border-radius: 6px;
}

.boatPage-fastBoatCards {
  position: absolute;
  width: 100%;
  z-index: 1;
  /* bottom: 0; */
}

.boatPage-footer {
  position: relative;
  /* bottom: -280px; */
}

.boatPage-carousalTitle {
  text-align: left;
  margin-top: 12px;
  font-weight: bold;
  font-size: 16px;
  margin-left: 8px;
  margin-bottom: 0px;
}

.carousel-control-prev {
  background-color: #8bc53e;
}

.ship-list-topIcon {
  position: absolute;
  right: 100px;
  top: -10px;
}

.ship-list-bottomIcon {
  position: absolute;
  right: 125px;
  bottom: 50px;
  z-index: -999;
}

@media (max-width: 767px) {

  .amenities-boat-heading-topIcon {
    left: 215px;
    top: -15px;
  }
  .amenities-boat-heading-bottomIcon {
    position: absolute;
    left:  65px;
    bottom: 80px;
    z-index: -9999;
  }
  .amenities-boat-heading {
    margin: 100px 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .amenities-boat-heading h1 {
    color: var(--black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.88px;
    text-align: start;
    margin-bottom: 10px;
  }

  .amenities-boat-heading p {
    display: flex;
    flex-wrap: wrap;
    color: var(--black, #050f15);
    text-align: start;
    font-family: "Montserrat" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }

  .ship-list-content h1 {
    color: var(--black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.88px;
    text-align: start;
    margin-bottom: 10px;
  }

  .ship-list-content p {
    display: flex;
    flex-wrap: wrap;
    color: var(--black, #050f15);
    text-align: center;
    font-family: "Montserrat" !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }

  .ship-list-topIcon {
    position: absolute;
    left: 210px;
    top: -20px;
  }

  .ship-list-bottomIcon {
    position: absolute;
    left: -30px;
    bottom: 40px;
    z-index: -999;
  }
}

@media (max-width: 576px) {
  .search-engine-wrapper{
    padding: 0px !important;
  }
  .boat-heading h1{
    font-size: 28px !important;
    margin-bottom: 0px !important;
  }
  .amenities-boat-heading {
    margin: 100px 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .amenities-boat-heading-topIcon {
    right: 78px;
    top: -12px;
  }
  .amenities-boat-heading-bottomIcon {
    position: absolute;
    right: 105px;
    bottom: 73px;
    z-index: -9999;
  }

  .amenities-boat-heading h1 {
    color: var(--black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.88px;
    text-align: left;
    margin-bottom: 10px;
  }

  .amenities-boat-heading p {
    display: flex;
    flex-wrap: wrap;
    color: var(--black, #050f15);
    text-align: start;
    font-family: "Montserrat" !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }


  .ship-list-content h1 {
    text-align: start;
    font-size: 25px !important;
  }
  
  .ship-list-topIcon {
    position: absolute;
    right: 85px;
    top: -17px;
  }
  .ship-list-bottomIcon img{
    width: 180px;
  }
  .ship-list-bottomIcon {
    position: absolute;
    left: 40px;
    bottom: 30px;
    z-index: -999;
  }
}