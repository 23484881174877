.acommodation-confirm-bg {
  padding: 80px 40px;
  border-radius: 16px;
  border: 1px solid rgba(5, 15, 21, 0.1);
  background: #fff;
}

.accomodation-confirmation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.accomodation-confirmation h1 {
  font-family: "Montserrat" !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.88px;
}

.accomodation-confirmation p {
  color: var(--neutrals-4, #777e90);
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.accomodation-host-contentWrapper {
  border-radius: 16px;
  background: #f3f3f5;
  gap: 10px;
  padding: 16px;
}

.accomodation-host-contentWrapper h5 {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.accomodation-host-content {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  margin-top: 5px;
}

.accomodation-host-content img {
  border-radius: 50px;
}

.accomodation-host-content p {
  color: var(--neutrals-4, #777e90);
  font-family: "Montserrat" !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.accomodation-right-content {
  font-family: "Montserrat" !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
}

.acommodationConfirm-btn button {
  border-radius: 8px;
  border: 1px solid var(--brand, #45aaf0);
  padding: 12px 0px;
  width: 100%;
  cursor: pointer;
  outline: none;
  color: var(--brand, #45aaf0);
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  background: white;
  outline: none;
  border: none;
}

.acommodationConfirm-btn button:hover {
  background: #45aaf0;
  color: white;
}