.allLocation-heading h1 {
  width: 505px;
  color: var(--color-main-black, #1a1a1a);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  position: relative;
}

.allLocation-topImg {
  position: absolute;
  right: 290px;
  top: -15px;
}

.allLocation-bottom {
  position: absolute;
  right: 325px;
  top: 30px;
  z-index: -9999;
}

.allLocation-bottom img {
  width: 220px;
}

.allLocation-heading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.allLocation-dropdown-wrapper {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.show-location button img {
  margin-right: 20px;
}

.allLocation-dropdown {
  width: 400px;
}

.show-location button {
  margin-left: 15px;
  color: #f8fbfc;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  border: none;
  outline: none;
  padding: 16px 16px;
  border-radius: 8px;
  background: var(--brand, #45aaf0);
}

.allLocation-img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-shrink: 0;
  border-radius: 16px;
  padding: 20px;
  opacity: 90%;
  height: 420px;
}

.all-locationPrice-wrapper {
  position: relative;
  bottom: -300px;
  color: #fff;
}

.allLocation-capariPrice {
  display: flex;
  justify-content: space-between;
}

.allLocation-capariPrice h6 {
  color: var(--secand, #8bc53e);
  font-family: "Montserrat" !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 28px */
  letter-spacing: -0.56px;
}

.allLocation-capariPrice h6 span {
  color: var(--secand, #fff);
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 28px */
  letter-spacing: -0.56px;
}

.allLocation-capariPrice p {
  margin: 0px;
}

.allLocationPage-fastBoatCards {
  position: absolute;
  width: 100%;
  /* bottom: 0; */
}

.allLocationPage-footer {
  position: relative;
  z-index: -1;
  /* bottom: -280px; */
}

.laocationModal-heading {
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}

.locationModal-content {
  display: flex;
  gap: 50px;
}

.locationModal-discription {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  width: 1200px;
}

.modalOkay-btn {
  padding: 8px 80px;
  color: #fff;
  outline: none !important;
  border: none;
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  outline: none;
  border: none;
}

.modalCookie-btn {
  border-radius: 8px;
  border: 1px solid rgba(5, 15, 21, 0.7);
  margin-top: 6px;
  padding: 8px 30px;
}



.pagination-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  align-items: center;
  gap: 10px;
}

.pagination-wrapper button {
  color: #f8fbfc;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  border: none;
  outline: none;
  padding: 8px 12px;
  border-radius: 8px;
  background: #8bc53e;
}

.pagination-wrapper span {
  color: #8bc53e;
  font-weight: 600;
}





@media (max-width: 768px) {
  .allLocation-heading h1 {
    width: 300px;
    text-align: left;
    font-size: 35px;
  }

  .allLocation-topImg {
    right: 105px;
    top: 145px;
  }

  .allLocation-bottom {
    right: 190px;
    top: 185px;
  }

  .allLocation-bottom img {
    width: 160px;
  }

  .show-location button {
    margin-left: 0px;
    margin-top: 20px;
    padding: 16px 85px;
  }

  .allLocation-dropdown-wrapper {
    width: 100%;
    padding: 0px 20px !important;
  }
}