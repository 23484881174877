.faSecurity-heading {
  padding-top: 20px;
}

.faSecurity-heading h1 {
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 22px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.84px;
}

.faSecurity-container {
  padding: 30px 30px;
  background: #f3f3f5;
}

.faSecurity-qrBg {
  margin-top: 40px;
  border-radius: 12px;
  background: #f8fbfc;
  padding: 40px 20px;
}

.faSecurity-input input {
  border: 1px solid #b8b8b8;
  background: #e7e7e7;
  padding: 22px 15px;
}

.faSecurity-input input::placeholder {
  color: rgba(5, 15, 21, 0.8);
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.72px;
}

.faSecurity-QrImg {
  padding: 60px 0px;
  display: flex;
  justify-content: center;
}

.faSecurity-button button {
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  width: 100%;
  outline: none;
  border: none;
  padding: 10px 0px;
}

.faSecurity-button button a {
  color: var(--white, #f8fbfc);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none !important;
}

.google-authenticator h2 {
  color: rgba(5, 15, 21, 0.8);
  font-family: "Montserrat" !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.72px;
}

.faSecurity-appBtn {
  padding: 25px 0px;
}

.faSecurity-appBtn button {
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  width: 100%;
  outline: none;
  border: none;
  padding: 10px 0px;

}

.faSecurity-appBtn button a {
  color: var(--white, #f8fbfc);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none !important;
}