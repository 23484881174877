body {
  overflow-x: hidden !important;
}
.container

/* .container-fluid,
.container-xl,
.container-lg,
.container-md, */
/* .container-sm  */
  {
  width: 100%;
  /* width: 1920px; */
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

/* @media (min-width: 576px) {

  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container-lg,
  .container-md, */
/* .container-sm,
  .container {
    max-width: 960px;
  }
} */

/* @media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
} */

.main-heading {
  margin: 70px 0px;
}

.main-heading h1 {
  color: var(--black, #050f15);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.main-heading p {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  text-align: center;
}

.left-container {
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
}

.left-container h1 {
  color: var(--color-main-black, #1a1a1a);
  font-family: "Montserrat" !important;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  /* width: 550px; */
}

.left-container p {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 28.8px */
  opacity: 0.800000011920929;
  /* width: 528px; */
}

.left-container button {
  /* display: flex; */
  padding: 12px 28px;
  /* justify-content: center;
  align-items: center;
  gap: 10px; */
  /* border-radius: 8px; */
  background: var(--brand, #45aaf0);
  /* outline: none; */
  font-weight: bold;
  margin-right: 10px;
  outline: none;
}

.weeklyTripBg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* .discover-location-wrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
  overflow: hidden;
} */

.locationPrice-wrapper {
  position: relative;
  bottom: -390px;
  color: #fff;
}

.location-capariPrice {
  display: flex;
  justify-content: space-between;
}

.location-capariPrice h6 {
  color: var(--secand, #8bc53e);
  font-family: "Montserrat" !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 28px */
  letter-spacing: -0.56px;
}

.location-capariPrice h6 span {
  color: var(--secand, #fff);
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 28px */
  letter-spacing: -0.56px;
}

.location-capariPrice p {
  margin: 0px;
}

.property-type {
  margin: 70px 0px;
  position: relative;
}

.property-type-topIcon {
  position: absolute;
  right: 310px;
  top: -5px;
}

.property-type-bottomIcon {
  position: absolute;
  right: 340px;
  bottom: 32px;
  z-index: -9999;
}

.weeklyTrip-type-topIcon {
  position: absolute;
  right: 362px;
  top: -18px;
}

.weeklyTrip-type-bottomIcon {
  position: absolute;
  right: 390px;
  bottom: 37px;
  z-index: -9999;
}

.property-type h1 {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  /* 57.2px */
  letter-spacing: -0.88px;
  text-align: center;
  margin-bottom: 10px;
}

.property-type p {
  color: var(--black, #050f15);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.best-boat-background {
  background: #f4f7f8;
  padding: 20px 0px;
}

.best-boat-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.best-boats-content {
  margin: 70px 0px;
  position: relative;
}

.boat-type-topIcon {
  position: absolute;
  right: 95px;
  top: -8px;
}

.best-boats-content h1 {
  position: relative;
}

.boat-type-bottomIcon {
  position: absolute;
  right: 122px;
  bottom: 82px;
}

.best-boat-button button {
  padding: 12px 28px;
  background: var(--brand, #45aaf0);
  font-weight: bold;
  margin-right: 10px;
  outline: none;
}



.best-boats-content p {
  width: 526px;
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.proprty-card-footer {
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin: 0 !important;
  text-decoration: none !important;
}

.boat-card-footer {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin: 0 !important;
  /* text-transform: none !important; */
}

.card-content-wrapper:hover {
  background: var(--secand, #8bc53e);
}

.card-boot-content-wrapper:hover {
  background: var(--secand, #8bc53e);
}

.boot-card-content-wrapper {
  background: #f4f7f8;
}

/* .boot-card-content-wrapper:hover {
  background: var(--secand, #8BC53E)
} */

.boat-wrapper {
  margin: 70px 0px 40px 0px;
}

.home-boat-card-content {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

/* Carousel */

.top-dealCard-content-heading {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.top-dealCard-content-discription p {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.top-dealCard-content-heading h2 {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px !important;
}

.top-dealCard-content-heading h3 {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px !important;
}

.top-dealCard-content-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-dealCard-content-button h3 {
  color: var(--secand, #8bc53e);
  font-family: "Montserrat" !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.56px;
}

.top-dealCard-content-button h3 span {
  color: rgba(5, 15, 21, 0.5);
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.32px;
}

.top-dealCard-content-button button {
  padding: 12px 24px;
  font-weight: bold;
  margin-right: 10px;
  color: #fff;
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  outline: none;
  border: none;
}

#carouselExampleIndicators {
  position: relative;
}

.carousel-indicators {
  position: absolute;
  bottom: 0;
  left: 30%;
  transform: translateX(-50%);
}

.carousel-indicators button {
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  width: 12px !important;
  height: 12px !important;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
}

.carousel-indicators button.active {
  background-color: #8bc53e;
}

.carousel-inner {
  position: relative;
}

.carousel-inner .carousel-item {
  display: none;
}

.carousel-inner .carousel-item.active {
  display: block;
}

.carousel-inner .carousel-item .home-page-slider {
  width: 100%;
  height: auto;
}

/* Card Content */
.card-content-wrapper {
  padding: 16px;
  padding-bottom: 16px !important;
  background-color: #f4f7f8;
  display: flex;
  justify-content: space-between;
  text-decoration: none !important;
}

.card-boot-content-wrapper {
  padding: 16px;
  padding-bottom: 16px !important;
  display: flex;
  justify-content: space-between;
  background: none;
}

.property-card-footer {
  font-size: 18px;
  font-weight: bold;
}

/* Additional Styling (Optional) */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 20px !important;
  /* height: 20px; */
}

.fa-chevron-right:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.carousel-control-prev,
.carousel-control-next {
  width: 35px;
  height: 35px;
  /* background-color: #050f15 !important; */
  background-color: #8bc53e;
  border: none;
  border-radius: 50%;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: baseline;
}

.carousel-control-prev {
  left: 10px;
}

.carousel-control-next {
  right: 10px;
  display: flex;
  align-items: center;
}

.carousel-control-prev:focus,
.carousel-control-prev:hover,
.carousel-control-next:focus,
.carousel-control-next:hover {
  /* background-color: rgba(65, 122, 247, 0.8); */
  background-color: rgba(76, 88, 59, 0.8);
}

.trip-enjoy-img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 306px;
  height: 493px;
  flex-shrink: 0;
  border-radius: 16px;
  padding: 20px;
}

.tripenjoy2-icon {
  position: absolute;
  left: -55px;
  bottom: -25px;
}

.enjoyTrip-wrapper {
  margin: 100px 0px;
}

.enjouTrip-heading {
  position: relative;
}

.enjouTrip-topIcon {
  position: absolute;
  right: 155px;
}

.enjouTrip-bottomIcon {
  position: absolute;
  right: 190px;
  bottom: 137px;
  z-index: -9999;
}

.enjouTrip-heading h1 {
  width: 580px;
  color: var(--color-main-black, #1a1a1a);
  font-family: "Montserrat" !important;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}

.enjouTrip-heading p {
  width: 530px;
  color: var(--color-gray-2, #535353);
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 20px !important;
}

.enjouTrip-content h1 {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.enjouTrip-content p {
  color: rgba(5, 15, 21, 0.8);
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  width: 230px;
}

.trip-enjoy-img1 {
  display: none;
}

.aa1 {
  position: absolute;
  right: 45px;
}

.aa2 {
  position: absolute;
  right: 65px;
  bottom: 287px;
  z-index: -9999;
}

.homePage-fastBoatCards {
  position: absolute;
  width: 100%;
  z-index: 1;
  /* bottom: 0; */
}

.homePage-footer {
  position: relative;
  /* bottom: -280px; */
}

.large {
  display: none;
}

.mobile {
  display: block;
}

.link-wrapper {
  text-decoration: none !important;
}

@media (min-width: 768px) {
  .discoverLocation-img {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 306px;
    height: 493px;
    flex-shrink: 0;
    border-radius: 16px;
    padding: 20px;
    opacity: 90%;
  }

  .right-container {
    margin: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 50%;
  }

  .best-boats-content {
    position: relative;
    display: flex;
    justify-content: end;
    flex-direction: column;
  }

  .best-boats-content h1 {
    color: var(--black2);
    font-family: "Montserrat" !important;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    z-index: 1;
    /* margin: 0px 90px; */
  }

  .best-boats-content h1 .img1 {
    position: absolute;
    left: 260px;
    bottom: 20px;
    z-index: -1;
  }

  .best-boats-content h1 .img2 {
    padding-bottom: 60px;
  }
}

@media (max-width: 970px) {
  .left-container {
    width: 100% !important;
    position: relative;
    display: flex;
    justify-content: center;

  }
  .trip-enjoy-img {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .large {
    display: block;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .mobile {
    display: none;
  }

  .tripenjoy2-icon {
    display: none !important;
  }

  .main-heading h1 {
    text-align: left;
    font-size: 35px;
  }

  .main-heading p {
    text-align: left;
    width: 300px;
  }

  .discover-location-wrapper {
    flex-wrap: wrap;
  }

  .left-container {
    padding-left: 0.75rem !important;
    margin-left: 0rem;
    margin-top: 70px !important;
    margin-bottom: 70px !important;
    width: 100% !important;
  }

  .left-container h1 {
    width: 335px;
    font-size: 32px;
  }

  .left-container p {
    width: 328px;
    font-size: 14px;
  }

  .discoverLocation-img {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 493px;
    flex-shrink: 0;
    border-radius: 16px;
    padding: 20px;
    opacity: 90%;
  }

  .right-container {
    margin: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .img3 {
    display: none;
  }

  .property-type h1 {
    font-size: 30px;
    text-align: left;
    width: 253px;
  }

  .property-type p {
    text-align: left;
    font-size: 16px;
    width: 283px;
  }

  .class1 {
    gap: 15px;
  }

  /* .best-boats-content h1 {
    font-size: 30px;
    text-align: left;
    width: 192px;
  } */

  .best-boats-content {
    position: relative;
    display: flex;
    justify-content: end;
    flex-direction: column;
  }

  .best-boats-content h1 {
    color: var(--black2);
    font-family: "Montserrat" !important;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    z-index: 1;
    text-align: left;
    /* margin: 0px 90px; */
  }

  .best-boats-content h1 .img1 {
    position: absolute;
    left: 0px;
    bottom: 20px;
    z-index: -1;
  }

  .best-boats-content h1 .img2 {
    padding-bottom: 35px;
  }

  .best-boats-content p {
    text-align: left;
    font-size: 16px;
    width: 283px;
  }

  .best-boat-content-wrapper {
    flex-wrap: wrap;
  }

  .best-boats-content {
    margin: 22px 0px;
  }

  .property-type h1 {
    width: 185px;
  }

  .enjouTrip-heading h1 {
    width: 292px;
    font-size: 30px;
  }

  .enjouTrip-heading p {
    width: 326px;
  }

  .trip-enjoy-img1 {
    display: block;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    /* width: 306px; */
    height: 493px;
    flex-shrink: 0;
    border-radius: 16px;
    padding: 20px;
  }

  .trip-enjoy-img {
    display: none;
  }

  .enjouTrip-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .enjouTrip-content {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin-bottom: 15px;
  }

  .aa1 {
    right: 38px;
  }

  .aa2 {
    right: 71px;
    bottom: 189px;
  }

  .property-type-topIcon {
    right: 145px;
    top: -15px;
  }

  .property-type-bottomIcon img {
    width: 170px;
  }

  .property-type-bottomIcon {
    right: 155px;
    bottom: 105px;
  }

  .boat-type-bottomIcon {
    right: 170px;
    bottom: 97px;
  }

  .boat-type-bottomIcon img {
    width: 120px;
  }

  .boat-type-topIcon {
    right: 100px;
    top: -12px;
    /* display: flex;
    justify-content: end; */
    position: absolute;
  }

  .weeklyTrip-type-topIcon {
    right: 150px;
    top: -12px;
  }

  .weeklyTrip-type-bottomIcon img {
    width: 130px;
  }

  .weeklyTrip-type-bottomIcon {
    right: 215px;
    bottom: 83px;
  }

  .enjouTrip-topIcon {
    right: 12px;
    top: -5px;
  }

  .enjouTrip-bottomIcon img {
    width: 220px;
  }

  .enjouTrip-bottomIcon {
    right: 47px;
    bottom: 113px;
  }
}

.property-buy-img {
  height: 100vh !important;
}