.request-heading h1 {
  color: var(--Black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 15px;
}

.request-bg {
  border-radius: 12px;
  background: var(--White, #f8fbfc);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.request-viewallBtn {
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  padding: 8px 15px;
  outline: none !important;
  border: none !important;
}

.request-viewallBtn a {
  color: white !important;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.request-viewallBtn:hover {
  background: #45aaf0;
}

.request-viewallWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
}

.request-viewallWrapper h2 {
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.84px;
  margin-left: 10px;
  margin-bottom: 0px !important;
}

.request-table-th {
  border-radius: 10px;
  background: #fff;
  color: rgba(5, 15, 21, 0.5);
  background-color: #45aaf0;
}

.request-table-th th {
  color: #fff;
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.request-td-wrapper td {
  color: #050f15;
  font-family: "Montserrat" !important;
  line-height: normal;
  background: #fff;
  border: none;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: normal;
}

.reviewRate-bg {
  background: #45aaf0;
  padding: 8px 0px;
  border-radius: 10px;
  width: 45px;
}

.reviewRate-bgWrapper {
  display: flex;
  justify-content: center;
}

.request-tablecontent {
  padding: 10px 0px;
  display: flex;
  justify-content: center;
}

.request-tablecontent p {
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.requestubmit-btn {
  padding: 8px 30px;
  color: #fff;
  outline: none !important;
  border: none;
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  outline: none;
  border: none;
}

.requestubmit-wrapper {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.requestmodal-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  outline: none !important;
}

.requestmodalcrollable-content {
  max-height: 450px;
  /* Adjust the height as needed */
  /* overflow-y: auto; */
}

.requestmodal-heading {
  background: var(--brand, #45aaf0);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.requestmodal-heading h1 {
  padding-left: 12px;
  color: #fff;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  margin-bottom: 0px !important;
}

.requestsubmit-inputs input {
  width: 100%;
  border: 1px solid #b8b8b8;
  background: #e7e7e7;
  padding: 15px 20px 50px 15px;
  border-radius: 10px;
  outline: none !important;
  border: none !important;
}

.requestsubmit-inputs {
  padding: 22px;
}
.reviews-bg {
  border-radius: 12px;
  background: var(--White, #f8fbfc);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 20px;
}

@media (min-width: 767px) {
  .propertyHistory-heading h1 {
    color: var(--Black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .propertyHistory-heading h1 {
    color: var(--Black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 15px;
    text-align: center;
  }

  .table-wrapper {
    /* max-height: clamp(5em); */
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
}
