.reset-content-wrapper {
    display: flex;
    justify-content: center;
}


.restPassword-heading h1 {
    color: var(--black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* width: 100%; */
    margin-bottom: 50px;
}

.restPassword-heading {
    position: relative;
}

.restPassword-topIcon {
    position: absolute;
    right: -20px;
    top: 145px;
}

.restPassword-bottomIcon {
    position: absolute;
    right: 20px;
    top: 195px;
    z-index: -9999;
}

.restPassword-bottomIcon img {
    width: 180px;
}

.restPassword-heading img {
    margin-bottom: 120px;
}

.login-right-img {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    flex-shrink: 0;
    border-radius: 16px;
    /* padding: 20px; */
    opacity: 90%;
}

.restPassword-email {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
}

.restPassword-email label {
    color: var(--black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.restPassword-email input {
    padding: 10px 14px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #eef3fb;
    background: var(--base-white, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}


.resetPassword-btn button {
    width: 100%;
    border: none;
    color: #fff;
    font-family: "Montserrat" !important;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 18px;
    border-radius: 8px;
    background: var(--brand, #45aaf0);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin-bottom: 30px;
    outline: none;
}

.signIn-link {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.signIn-link p {
    font-family: "Montserrat" !important;
    font-size: 14px;
}

.signIn-link p a {
    color: var(--brand, #45aaf0);
    font-family: "Montserrat" !important;
    font-size: 15px;
    margin-left: 5px;
}

.resetPassword-carousel-control-prev {
    /* left: 0; */
    bottom: 0;
    /* right: 0; */
}

.resetPassword-carousel-control-next {
    bottom: 500px !important;
}

.carousel-caption {
    position: absolute;
    bottom: 25px;
    left: 20px;
    color: #fff;
    padding: 10px;
    max-width: 755px;
}

.carousel-caption h2 {
    margin-bottom: 20px;
    font-family: "Montserrat" !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
}

.carousel-caption h3 {
    font-family: "Montserrat" !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    margin: 0;
}

.carousel-caption p {
    font-family: "Montserrat" !important;
    font-size: 15px !important;
    text-align: left;
}



#resetPasswordcarouselExampleIndicator {
    position: relative;
}

.carousel-indicators-resetPassword {
    position: absolute;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.carousel-indicators-resetPassword button {
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
}

.carousel-indicators-resetPassword button.active {
    background-color: #8bc53e;
}

.carousel-inner {
    position: relative;

}

.login-carousel-inner .carousel-item {
    display: none;


}

.login-carousel-inner .carousel-item.active {
    display: block;

}

.login-carousel-inner .carousel-item img {
    width: 100%;
    height: auto;
}





.resetPassword-carousel-control-prev-icon,
.resetPassword-carousel-control-next-icon {
    font-size: 20px;
    color: #FFF !important;
}

.resetPassword-carousel-control-prev,
.resetPassword-carousel-control-next {
    width: 40px;
    height: 40px;
    background: none;

    border: 1px solid #FFF;
    border-radius: 50%;
    position: absolute;
    /* top: 50%; */
    bottom: 5% !important;
    /* right: 0% !important; */
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    justify-content: center;
}


.resetPassword-carousel-control-prev {
    /* left: 10px; */
    right: 15%;
    color: #FFF !important;
    z-index: 99;
}

.resetPassword-carousel-control-next {
    right: 5%;
}

.resetPassword-carousel-control-prev:focus,
.resetPassword-carousel-control-prev:hover,
.resetPassword-carousel-control-next:focus,
.resetPassword-carousel-control-next:hover {
    background-color: #8bc53e;
    z-index: 99;
    outline: none;
}













@media (max-width: 768px) {
    .restPassword-heading h1 {
        font-size: 26px;
    }

    .contactUs-topIcon {
        right: 49px;
        top: 112px;
    }

    .important-links ul li {
        margin-bottom: 10px;
    }

    .restPassword-topIcon {
        right: 50px;
    }

    .restPassword-bottomIcon {
        right: 80px;
        top: 190px;
    }

    .restPassword-bottomIcon img {
        width: 140px;
    }

}