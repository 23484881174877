.login-form {
  color: white;
  margin: 2rem auto;
  padding: 2rem 20px;
  width: 90%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}
.login-form h1 {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 20px;
}

.login-form {
  position: relative;
}

.loginHeading-topIcon {
  position: absolute;
  right: 125px;
  top: 90px;
}

.loginHeading-bottomIcon {
  position: absolute;
  right: 100px;
  top: 130px;
  z-index: -9999;
}

.login-form img {
  margin-bottom: 30px;
}

.login-right-img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  flex-shrink: 0;
  border-radius: 16px;
  /* padding: 20px; */
  opacity: 90%;
}

.login-email {
  display: flex;
  flex-direction: column;
}

.login-email label {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.login-email input {
  padding: 10px 14px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #eef3fb;
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.login-password {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.login-password label {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.login-password input {
  padding: 10px 14px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #eef3fb;
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.remember-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.remember-me label {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.signIn-btn button {
  width: 100%;
  border: none;
  color: #fff;
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 18px;
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-bottom: 15px;
  outline: none;
}

.google-login {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.google-login button {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #eef3fb;
  background: var(--base-white, #fff);
  outline: none;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.google-login p {
  font-family: "Montserrat" !important;
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 0;
}

.google-login img {
  width: 24px;
  margin-bottom: 0;
}

.facebook-login {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.facebook-login button {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #eef3fb;
  background: var(--base-white, #fff);
  outline: none;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.facebook-login img {
  width: 24px;
  margin-bottom: 0;
}

.facebook-login p {
  font-family: "Montserrat" !important;
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 0;
}

.signUp-link {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.signUp-link p {
  font-family: "Montserrat" !important;
  color: black;
  font-size: 14px;
}

.signUp-link p a {
  color: var(--brand, #45aaf0);
  font-family: "Montserrat" !important;
  font-size: 15px;
  margin-left: 5px;
}

.login-carousel-control-prev {
  /* left: 0; */
  bottom: 0;
  /* right: 0; */
}

.login-carousel-control-next {
  bottom: 500px !important;
}

.carousel-caption {
  position: absolute;
  bottom: 25px;
  left: 20px;
  color: #fff;
  padding: 10px;
  max-width: 755px;
}

.carousel-caption h2 {
  margin-bottom: 20px;
  font-family: "Montserrat" !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.carousel-caption h3 {
  font-family: "Montserrat" !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  margin: 0;
}

.carousel-caption p {
  font-family: "Montserrat" !important;
  font-size: 15px !important;
  text-align: left;
}

#logincarouselExampleIndicator {
  position: relative;
}

.carousel-indicators-login {
  position: absolute;
  bottom: 14px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.carousel-indicators-login button {
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
}

.carousel-indicators-login button.active {
  background-color: #8bc53e;
}

.carousel-inner {
  position: relative;
}

.login-carousel-inner .carousel-item {
  display: none;
}

.login-carousel-inner .carousel-item.active {
  display: block;
}

.login-carousel-inner .carousel-item img {
  width: 100%;
  height: auto;
}

.login-carousel-control-prev-icon,
.login-carousel-control-next-icon {
  font-size: 20px;
  color: #fff !important;
}

.login-carousel-control-prev,
.login-carousel-control-next {
  width: 40px;
  height: 40px;
  background: none;

  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  /* top: 50%; */
  bottom: 5% !important;
  /* right: 0% !important; */
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.login-carousel-control-prev {
  /* left: 10px; */
  right: 15%;
  color: #fff !important;
  z-index: 99;
}

.login-carousel-control-next {
  right: 5%;
}

.login-carousel-control-prev:focus,
.login-carousel-control-prev:hover,
.login-carousel-control-next:focus,
.login-carousel-control-next:hover {
  background-color: #8bc53e;
  z-index: 99;
}

@media (max-width: 768px) {
  .login-heading h1 {
    font-size: 23px;
  }

  .contactUs-topIcon {
    right: 49px;
    top: 112px;
  }

  .important-links ul li {
    margin-bottom: 10px;
  }

  .loginHeading-topIcon {
    right: 10px;
    top: 80px;
  }

  .loginHeading-bottomIcon {
    right: 0px;
    top: 130px;
  }
}
@media (max-width: 475px) {
  .remember-wrapper {
    flex-direction: column;
    align-items: start;
  }
}
@media (min-width: 425px) {
  .login-form h1 {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .login-form {
    width: 60%;
  }
}
