.table-heading h1 {
  color: var(--Black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 10px;
}

.AddSupportTickets-bg {
  border-radius: 12px;
  background: var(--White, #f8fbfc);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.AddSupportTickets-container {
  padding: 30px 30px;
  background: #f3f3f5;
}

.AddSupportTickets-contentBg {
  margin-top: 40px;
  border-radius: 12px;
  background: #f8fbfc;
  padding: 40px 20px;
}

.AddSupportTickets-dropdown {
  outline: none;
  border: none;
  display: block;
  font-size: 14px;
  width: inherit;
  color: #bbbbbb;
}

.AddSupportTickets-dropdown input {
  width: 100%;
  border-radius: 6px;
  font-size: 12px;
}

.AddSupportTickets-dropdown select {
  border-radius: 6px;
  background: #f3f3f5;
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: none;
  width: 100%;
  height: 45px;
  padding: 0px 10px;
}

.AddSupportTickets-name-inputs input {
  border-radius: 6px;
  background: #e3e3e3;
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  opacity: 0.6;
}

.AddSupportTickets-inputs input {
  border-radius: 6px;
  background: #f3f3f5;
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: none;
}

.AddSupportTickets-message-input input {
  border-radius: 6px;
  background: #f3f3f5;
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: none;
  padding: 20px 20px 120px 20px;
}

.AddSupportTickets-inputLabel {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.AddSupportTickets-inputLabel span {
  color: red;
}

.AddSupportTickets-attachment {
  padding: 35px 0px;
  background: #f3f3f5;
}

.AddSupportTickets-attachmentContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.AddSupportTickets-attachmentContent p {
  color: var(--neutrals-4, #777e90);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-top: 10px;
}

.AddSupportTickets-attachmentText {
  color: rgba(5, 15, 21, 0.5);
  font-family: "Montserrat" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 5px;
}

.AddSupportTickets-submitBtn {
  padding-top: 100px;
  padding-bottom: 10px;
}

.AddSupportTickets-submitBtn button {
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  width: 100%;
  outline: none;
  border: none;
  padding: 10px 0px;
  width: 100%;
}

.AddSupportTickets-submitBtn button a {
  color: var(--white, #f8fbfc);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none !important;
}


.AddSupportTickets-addBtn {
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  padding: 5px 15px;
  outline: none !important;
  border: none !important;
}

.AddSupportTickets-addBtn a {
  color: white !important;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.AddSupportTickets-addBtn:hover {
  background: #45aaf0;
}

.AddSupportTickets-btnWrapper {
  display: flex;
  align-items: center;
  padding: 30px 0px;
}

.AddSupportTickets-btnWrapper h2 {
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.84px;
  margin-left: 10px;
  margin-bottom: 0px !important;
}

@media (min-width: 767px) {
  .page-container {
    background: #F3F3F5;
    padding: 120px;
  }
}

@media (max-width: 767px) {
  .table-heading h1 {
    color: var(--Black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 15px;
    text-align: center;
  }
}