.profile-container {
    position: relative;
    display: inline-block;

}

.profile-pic {
    max-width: 100%;
    height: auto;
    display: block;
}

.edit-icon {
    position: absolute;
    bottom: 21px;
    right: 11px;
    transform: translate(50%, 50%);
    border-radius: 50%;
    padding: 5px;

}

.p-tag-color {
    color: #050F15;
}

.profileCard {
    background-color: #F8FBFC;
    padding: 19px 10px;
    border-radius: 11px;
}


.ticket_info b span {
    color: #8BC53E;
}

.hr {
    width: 92%;
}

.anchorTagcolor {
    color: #8BC53E;
}

.form-control-plaintext {
    background-color: #f3f3f5;
}

.form-control-plaintext:focus {
    outline: none;
}