.loginSecurity-bg {
  border-radius: 12px;
  background: var(--White, #f8fbfc);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.loginSecurity-heading h1 {
  color: var(--Black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 10px;
}



.loginSecurity-title {
  padding-top: 20px;
}

.loginSecurity-title h1 {
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 22px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.84px;
}


.loginSecurity-qrBg {
  margin-top: 40px;
  border-radius: 12px;
  background: #f8fbfc;
  padding: 10px 20px;
}

.loginSecurity-input input {
  border: 1px solid #b8b8b8;
  background: #e7e7e7;
  padding: 22px 15px;
}

.loginSecurity-input input::placeholder {
  color: rgba(5, 15, 21, 0.8);
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.72px;
}

.loginSecurity-QrImg {
  padding: 60px 0px;
  display: flex;
  justify-content: center;
}

.loginSecurity-button button {
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  width: 100%;
  outline: none;
  border: none;
  padding: 10px 0px;
}

.loginSecurity-button button a {
  color: var(--white, #f8fbfc);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none !important;
}

.loginGoogle-authenticator h2 {
  color: rgba(5, 15, 21, 0.8);
  font-family: "Montserrat" !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.72px;
}

.loginSecurity-appBtn {
  padding: 25px 0px;
}

.loginSecurity-appBtn button {
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  width: 100%;
  outline: none;
  border: none;
  padding: 10px 0px;
}

.loginSecurity-appBtn button a {
  color: var(--white, #f8fbfc);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none !important;
}