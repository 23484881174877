.modalOk-btn button {
  background: #45aaf0 !important;
  outline: none;
  border: none;
}
.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon-boxcheck {
  background: #45aaf0;
  color: #fff;
  border: none;
  font-weight: bold;
  border-radius: 20px;
}
