.withdrawMoney-container {
  background: #f3f3f5;
  padding: 30px 30px;
}

.withdrawMoney-Bg {
  border-radius: 20px;
  background: #f8fbfc;
  padding: 20px;
}

.withdrawMoney-contentBg {
  border-radius: 6px;
  background: linear-gradient(134deg,
      rgba(69, 170, 240, 0.21) 10.83%,
      rgba(69, 170, 240, 0) 100%);
  box-shadow: 0px 0px 4px 0px rgba(69, 170, 240, 0.4);
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.withdrawMoney-contentBg h1 {
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.54px;
  margin-bottom: 0px !important;
}

.withdrawMoney-contentBg p {
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.54px;
  margin-bottom: 0px !important;
}

.withdrawMoney-amountBg {
  border-radius: 6px;
  background: linear-gradient(134deg,
      rgba(69, 170, 240, 0.21) 10.83%,
      rgba(69, 170, 240, 0) 100%);
  box-shadow: 0px 0px 4px 0px rgba(69, 170, 240, 0.4);
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.withdrawMoney-input input {
  border: 1px solid #45aaf0;
  background: #f8fbfc;
  color: rgba(5, 15, 21, 0.5);
  font-family: "Montserrat" !important;
  font-size: 20px;
}

.withdrawMoney-input span {
  border: 1px solid #45aaf0;
  font-family: "Montserrat" !important;
  font-size: 20px;
}

.withdrawMoney-amountHeading h1 {
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.54px;
  margin-bottom: 0px !important;
}

.withdrawMoney-btn {
  display: flex;
  justify-content: center;
}

.withdrawMoney-btn button {
  border-radius: 6px;
  border: 1px solid #45aaf0;
  background: linear-gradient(134deg, #45aaf0 0%, rgba(69, 170, 240, 0) 100%);
  box-shadow: 0px 0px 4px 0px rgba(69, 170, 240, 0.4);
  padding: 10px 150px;
  outline: none !important;
  border: none !important;
}

.withdrawMoney-btn button a {
  color: #fff;
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.66px;
  text-decoration: none !important;
}