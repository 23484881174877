.profile-container {
  padding: 30px 30px;
  background: #f3f3f5;
}

.profile-pic {
  max-width: 100%;
  height: auto;
  display: block;
}

.edit-icon {
  position: absolute;
  bottom: 21px;
  right: 11px;
  transform: translate(50%, 50%);
  border-radius: 50%;
  padding: 5px;
}

.p-tag-color {
  color: #050f15;
}

.profileCard {
  background-color: #f8fbfc;
  padding: 19px 10px;
  border-radius: 11px;
}

.ticket_info b span {
  color: #8bc53e;
}

.hr {
  width: 100%;
}

.anchorTagcolor {
  color: #8bc53e;
}

.form-control-plaintext {
  background-color: #f3f3f5;
}

.form-control-plaintext:focus {
  outline: none;
}

@media (min-width: 768px) {
  .header-box {
    height: 40px;
  }
}
@media (max-width: 768px) {
  .header-box {
    height: 50px;
  }
}
