.propertyHistory-heading h1 {
  color: var(--Black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 15px;
}

.propertyHistory-bg {
  border-radius: 12px;
  background: var(--White, #f8fbfc);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.propertyHistory-title h2 {
  color: #000;
  font-family: "Montserrat" !important;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 25px;
}

.propertyHistory-table-th {
  border-radius: 10px;
  background: #fff;
  color: rgba(5, 15, 21, 0.5);
  background-color: #45aaf0;
}

.propertyHistory-table-th th {
  color: #fff;
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.propertyHistory-td-wrapper td {
  color: #050f15;
  font-family: "Montserrat" !important;
  line-height: normal;
  background: #fff;
  border: none;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: normal;
}

.propertymodal-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  outline: none !important;
}

.scrollable-content {
  max-height: 450px;
  /* Adjust the height as needed */
  overflow-y: auto;
}

.propertymodal-heading {
  background: var(--brand, #45aaf0);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.propertymodal-heading h1 {
  padding-left: 12px;
  color: #fff;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  margin-bottom: 0px !important;
}

.propertymodal-inputs input {
  width: 100%;
  border: 1px solid #b8b8b8;
  background: #e7e7e7;
  padding: 15px 20px 50px 15px;
  border-radius: 10px;
  outline: none !important;
  border: none !important;
}

.propertymodal-inputs {
  padding: 22px;
}
.table-wrapper {
  /* max-height: clamp(5em); */
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

@media (min-width: 767px) {
  .page-container {
    background: #f3f3f5;
    padding: 120px;
  }
}

@media (max-width: 767px) {
}
