


.profile-container {
    position: relative;
    display: inline-block;

}

.profile-pic {
    max-width: 100%;
    height: auto;
    display: block;
}

.edit-icon {
    position: absolute;
    bottom: 21px;
    right: 11px;
    transform: translate(50%, 50%);
    border-radius: 50%;
    padding: 5px;

}

.p-tag-color {
    color: #050F15;
}
.profilee{
    display: flex;
    align-items: center;
    justify-content: center;
}
.profileCard {
    background-color: #F8FBFC;
    padding: 19px 10px;
    border-radius: 11px;
}

.profileCard .profile-details{
    display: flex;
    align-items: center;
    justify-content: center;
}
.ticket_info b span {
    color: #8BC53E;
}

.hr {
    width: 100%;
}

.anchorTagcolor {
    color: #8BC53E;
}

.form-control-plaintext {
    background-color: #f3f3f5;
}

.form-control-plaintext:focus {
    outline: none;
}
.user_profile_info{
    display: flex;
}
.profile_info p{
        width: 100% !important;
    }
@media (max-width:600px) {
    .profilee{
        display: block;
        
    }
    .profileCard .profile-details{
        flex-direction: row !important;
    }
    
}
@media (max-width:968px) {
    .user_profile_info{
        display: flex;
        flex-direction: column;
    }
    .profileCard .profile-details{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .profileCard .profile-details .ticket_info {
        text-align: center;
    }
    .profileCard .profile-details .ticket_info p{
        font-size: 16px;
    }
    .profileCard .profile-details .ticket_info span{
        font-size: 30px;
      
    }
    .dflex{
        display: block;
    }

    
}