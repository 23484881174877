.contactUs-heading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #f3f3f5;
  align-items: center;
  padding: 50px 0px;
}

.contactUs-heading h1 {
  font-family: "Montserrat" !important;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
}

.contactUs-heading p {
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.contactUs-heading p span {
  color: #8bc53e;
}

.contactUs-cards {
  display: flex;
  padding: 20px 24px 32px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  background: #f8fbfc;
  height: 260px;
  /* box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75); */
}

.contactUs-cards img {
  margin-bottom: 12px;
}

.contactUs-cards h3 {
  color: #1a1a1a;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 15px;
}

.contactUs-cards p {
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  width: 290px;
  opacity: 0.7;
  margin-bottom: 0px !important;
}

.contactUs-fromBg {
  display: flex;
  padding: 40px 40px 80px 40px;
  flex-shrink: 0;
  border-radius: 16px;
  background: #f8fbfc;
}

.contactUs-formContent h1 {
  font-family: "Montserrat" !important;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  width: 451px;
}

.contactUs-formContent p {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.9;
  width: 476;
}

.form-btn button {
  /* display: flex;
width: 193px;
height: 56px; */
  outline: none;
  border: none;
  padding: 10px 18px;
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #ffffff;
  font-weight: 600;
  outline: none;
  border: none;
}

.contactForm-content h1 {
  font-family: "Montserrat" !important;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 15px;
}

.contactForm-content p {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 25.6px */
  width: 476px;
  opacity: 0.9;
  margin-bottom: 15px;
}

.contactForm-content img {
  margin-bottom: 10px;
}

.contactUs-subtitle {
  font-family: "Montserrat" !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
}

.contactUs-inputs {
  display: flex;
  flex-direction: column;
}

.contactUs-inputs label {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.contactUs-inputs input {
  padding: 10px 14px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #eef3fb;
  background: #f3f3f5 !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-family: "Montserrat" !important;
  font-size: 16px;
  opacity: 0.6;
}

.contactUs-fastBoatCards {
  position: absolute;
  width: 100%;
  z-index: 1;
  /* bottom: 0; */
}

.contactUs-footer {
  position: relative;
  /* bottom: -280px; */
}

@media (max-width: 768px) {
  .contactUs-heading {
    text-align: left;
    justify-content: start;
    align-items: flex-start;
    padding-left: 30px;
  }

  .contactUs-heading h1 {
    font-size: 32px;
  }

  .contactUs-subtitle {
    margin-top: 15px;
  }


  .contactForm-content h1 {
    width: 312px;
    font-size: 26px;
  }

  .contactForm-content p {
    font-size: 15px;
    width: 335px;
  }

  .contactForm-content img {
    width: 100%;
  }

  .form-btn button {
    width: 100%;
  }
}