.email-webBg {
  padding: 150px;
}

.email-webLogo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.email-webLogo img {
  width: 250px;
}

.email-webLogo h1 {
  color: var(--Black, #222);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 43px;
  margin-top: 80px;
  margin-bottom: 20px;
}

.email-webContent p {
  color: var(--Black, #222);
  /* Body Text */
  font-family: Open Sans !important;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 30px;
}

.email-webCallAction button {
  border-radius: 5px;
  background: var(--brand, #45aaf0);
  color: #fff;
  border: none;
  outline: none;
  padding: 10px 40px;
}

.email-webCallAction button a {
  color: var(--White, #fff);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  text-decoration: none !important;
}

.email-webPoints ul li {
  color: var(--Black, #222);
  /* Body Text */
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-left: 20px;
}

.email-webGoodluck p {
  color: var(--Black, #222);
  /* Body Text */
  font-family: "Montserrat" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}

.email-webFooter {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.email-webFooter p {
  color: var(--Dark-Grey, #999);
  text-align: center;
  /* Footer Text */
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 380px;
}