.fastBoat-wrapper {
    /* padding: 0px 110px; */
    width: 100% !important;
    margin: 0px !important;
}

.fastBoat-img {
    background-position: center center;
        background-repeat: no-repeat;
        /* width: 100%; */
        height: 335px;
    box-shadow: 0px 12px 36px 0px rgba(255, 255, 255, 0.07);
}



.fastBoat-content-wrapper {
    border-radius: 16px;
    background: #FFF;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
    position: absolute;
    bottom: 50px;
    width: 80%;
}


.fastBoat-content-icon {
    background: #45AAF0;
    padding: 5px 8px;
    border-radius: 50px;
    color: #FFF;
    font-weight: bold !important;
}
@media (max-width: 768px){
    .fastBoat-wrapper {
        padding: 33px;
    }
}