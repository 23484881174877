.blog-detailPage-heading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #f3f3f5;
  align-items: center;
  padding: 50px 0px;
}


.blog-detailPage-heading p {
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.blog-detailPage-heading p span {
  color: #8bc53e;
  margin-left: 5px;
}

.blogIndividual-mainImg img {
  width: 100%;
}

.blogIndividual-hotelDate {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.blogIndividual-hotelDate h6 {
  color: #8bc53e;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  border-radius: 6px;
  background: rgba(139, 197, 62, 0.1);
  padding: 8px 20px;
}

.blogIndividual-hotelDate p {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.7;
}

.blogIndividual-hotelName h1 {
  font-family: "Montserrat" !important;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}



.blogIndividual-dvidText {
  padding: 32px;
  gap: 10px;
  border-radius: 16px;
  background: #f8fbfc;
  margin-bottom: 30px;
}

.blogIndividual-dvidText h3 {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: black;
  margin-top: 10px;
}

.blogIndividual-dvidText h1 {
  font-family: "Montserrat" !important;
  margin-top: 15px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-left: 7px;
}

.blogIndividul-text h3 {
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  width: 740px;
  margin-top: 30px;
  margin-bottom: 50px;
}

.realted-blogs-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.realted-blogs-content {
  margin: 70px 0px;
  position: relative;
}

.realted-blogs-content h1 {
  font-family: "Montserrat" !important;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
}



.realted-blogs-button button {
  padding: 12px 28px;
  background: #45aaf0;
  font-weight: bold;
  margin-right: 10px;
  outline: none;
  color: white;
  border: none;
  border-radius: 8px;
}

.blogIndividual-fastBoatCards {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.blogIndividual-footer {
  position: relative;
  /* bottom: -280px; */
}

.blogIndividul-contactText h1 {
  font-family: "Montserrat" !important;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}

.blogIndividul-contactText p {
  width: 730px;
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.9;
}

.blogIndividual-rightBg {
  padding: 20px;
  border-radius: 16px;
  background: #f8fbfc;
}

.blogIndividual-search h3 {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.blogIndividual-searchInput input {
  border-radius: 8px;
  background: #f3f3f5;
  border: none;
  padding: 15px 10px;
}

.blogIndividual-recentCards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background: #fff;
  border-radius: 8px;
}

.blogIndividual-recentContent {
  padding: 5px;
}

.blogIndividual-recentContent h1 {
  font-family: "Montserrat" !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}

.blogIndividual-recentContent p {
  font-family: "Montserrat" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.7;
}





.wallet-tabs button {
  border-radius: 5px;
  background: none;
  color: #fff;
  border: none;
  outline: none;
}

.wallet-transaction-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}

.wallet-tabs {
  padding: 10px;
  gap: 8px;
  border-radius: 8px;
  background: #f3f3f5;
  border: none;
  outline: none !important;
}

.options-text {
  color: inherit;
  cursor: pointer;
  font-size: 18px !important;
}

.wallet-selected-tab {
  background: #8bc53e !important;
  border-radius: 10px;
  outline: none !important;
  border: none !important;
}

.wallet-selected-tab p {
  color: #fff !important;
}


.blogIndividual-slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background: #fff;
  border-radius: 8px;
}

.blogIndividual-slider img {
  height: 50px;
}



.blogIndividual-smallCards {
  /* display: flex; */
  /* align-items: center; */
  /* padding: 5px; */
  background: #f3f3f5 !important;
  border-radius: 8px;
}

.blogIndividual-smallCards img {
  width: 100% !important;
}


.blogIndividual-smallCardContent {
  padding-top: 10px;
}

.blogIndividual-smallCardContent h1 {
  font-family: "Montserrat" !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
}

.blogIndividual-smallCardContent p {
  font-family: "Montserrat" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.7;
  text-align: left;
  margin-top: 15px;
}

.blogCraousal .carousel .thumbs-wrapper {
  margin: 0px;
}

@media (min-width: 767px) {
  .blog-detailPage-heading h1 {
    font-family: "Montserrat" !important;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
  }

  .blogIndividual-hotelName p {
    font-family: "Montserrat" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 28.8px */
    opacity: 0.9;
    width: 730px;
  }

  .realted-blogs-content p {
    width: 450px;
    font-family: "Montserrat" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    opacity: 0.8;
  }

  .realted-blogs-topIcon {
    position: absolute;
    right: 160px;
    top: -10px;
  }

  .realted-blogs-bottomIcon {
    position: absolute;
    right: 180px;
    bottom: 73px;
    z-index: -9999;
  }
}

@media (max-width: 767px) {
  .blog-detailPage-heading h1 {
    font-family: "Montserrat" !important;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    padding: 10px;
    text-align: center;
  }

  .blogIndividual-hotelName p {
    font-family: "Montserrat" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 28.8px */
    opacity: 0.9;
  }

  .realted-blogs-content p {
    font-family: "Montserrat" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    opacity: 0.8;
  }

  .realted-blogs-topIcon {
    position: absolute;
    right: 50px;
    top: -10px;
  }

  .realted-blogs-bottomIcon {
    position: absolute;
    right: 73px;
    bottom: 103px;
    z-index: -9999;
  }
}