.signUp-content-wrapper {
    display: flex;
    justify-content: center;
}

.signUp-heading h1 {
    color: var(--black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* width: 100%; */
    margin-bottom: 25px;
}

.signUp-heading {
    position: relative;
}

.SignUpHeading-topIcon {
    position: absolute;
    right: -41px;
    top: 50px;
}

.SignUpHeading-bottomIcon {
    position: absolute;
    right: -10px;
    top: 90px;
    z-index: -9999;
}

.signUp-heading img {
    margin-bottom: 25px;
}

.login-right-img {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    flex-shrink: 0;
    border-radius: 16px;
    /* padding: 20px; */
    opacity: 90%;
}

.signUp-email {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.signUp-email label {
    color: var(--black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.signUp-email input {
    padding: 10px 14px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #eef3fb;
    background: var(--base-white, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.signUp-phone label {
    color: var(--black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}



input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.signUp-password {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.signUp-password label {
    color: var(--black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.signUp-password input {
    padding: 10px 14px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #eef3fb;
    background: var(--base-white, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.signUp-password p {
    font-family: "Montserrat" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

}

.signUp-btn button {
    width: 100%;
    border: none;
    color: #fff;
    font-family: "Montserrat" !important;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 18px;
    border-radius: 8px;
    background: var(--brand, #45aaf0);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin-bottom: 10px;
    outline: none;
}

.google-signUp {
    display: flex;
    justify-content: center;
}

.google-signUp button {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    width: 100%;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #EEF3FB;
    background: var(--base-white, #FFF);
    outline: none;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.google-signUp p {
    font-family: "Montserrat" !important;
    font-size: 16px;
    margin-left: 10px;
    margin-bottom: 0;
}

.google-signUp img {
    width: 24px;
    margin-bottom: 0;
}

.signIn-link {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.signIn-link p {
    font-family: "Montserrat" !important;
    font-size: 14px;
}

.signIn-link p a {
    color: var(--brand, #45aaf0);
    font-family: "Montserrat" !important;
    font-size: 15px;
    margin-left: 5px;
}

.signUp-carousel-control-prev {
    /* left: 0; */
    bottom: 0;
    /* right: 0; */
}

.signUp-carousel-control-next {
    bottom: 500px !important;
}

.carousel-caption {
    position: absolute;
    bottom: 25px;
    left: 20px;
    color: #fff;
    padding: 10px;
    max-width: 755px;
}

.carousel-caption h2 {
    margin-bottom: 20px;
    font-family: "Montserrat" !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
}

.carousel-caption h3 {
    font-family: "Montserrat" !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    margin: 0;
}

.carousel-caption p {
    font-family: "Montserrat" !important;
    font-size: 15px !important;
    text-align: left;
}



#signUpcarouselExampleIndicator {
    position: relative;
}

.carousel-indicators-login {
    position: absolute;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.carousel-indicators-login button {
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
}

.carousel-indicators-login button.active {
    background-color: #8bc53e;
}

.carousel-inner {
    position: relative;

}

.login-carousel-inner .carousel-item {
    display: none;


}

.login-carousel-inner .carousel-item.active {
    display: block;

}

.login-carousel-inner .carousel-item img {
    width: 100%;
    height: auto;
}





.signUp-carousel-control-prev-icon,
.signUp-carousel-control-next-icon {
    font-size: 20px;
    color: #FFF !important;
}

.signUp-carousel-control-prev,
.signUp-carousel-control-next {
    width: 40px;
    height: 40px;
    background: none;

    border: 1px solid #FFF;
    border-radius: 50%;
    position: absolute;
    /* top: 50%; */
    bottom: 5% !important;
    /* right: 0% !important; */
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    justify-content: center;
}


.signUp-carousel-control-prev {
    /* left: 10px; */
    right: 15%;
    color: #FFF !important;
    z-index: 99;
}

.signUp-carousel-control-next {
    right: 5%;
}

.signUp-carousel-control-prev:focus,
.signUp-carousel-control-prev:hover,
.signUp-carousel-control-next:focus,
.signUp-carousel-control-next:hover {
    background-color: #8bc53e;
    z-index: 99;
}

@media (min-width: 768px) {
    .signUp-phone select {
        padding: 13px 3px;
        align-items: center;
        border-radius: 8px 0px 0px 8px;
        border: 1px solid #eef3fb;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border-right: none;
        min-width: 100px;
        /* font-size: 16px; */
        background-color: #8BC53E;
        color: #fff;
        -webkit-appearance: none;
    }

    
.signUp-phone input {
    padding: 10px 14px;
    align-items: center;
    min-width: 20rem;
    border-radius: 0px 8px 8px 0px;
    border: 1px solid #eef3fb;
    background: var(--base-white, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
    
}
@media (max-width: 768px) {
    .signUp-heading h1 {
        font-size: 26px;
    }

    .contactUs-topIcon {
        right: 49px;
        top: 112px;
    }

    .important-links ul li {
        margin-bottom: 10px;
    }

    .signUp-phone select {
        padding: 13px 3px;
        align-items: center;
        border-radius: 8px 0px 0px 8px;
        border: 1px solid #eef3fb;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border-right: none;
        /* font-size: 16px; */
        background-color: #8BC53E;
        color: #fff;
        -webkit-appearance: none;
    }
    

    .signUp-phone input {
        padding: 10px 14px;
        align-items: center;
        max-width: 20rem;
        border-radius: 0px 8px 8px 0px;
        border: 1px solid #eef3fb;
        background: var(--base-white, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
}