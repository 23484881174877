.footer-background {
  background: #0f212c;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.contactUs-wrapper {
  /* padding: 80px; */
  position: relative;
}

.footer-contactUs-topIcon {
  position: absolute;
  right: 55px;
  top: -10px;
}

.contactUs-bottomIcon {
  position: absolute;
  right: 90px;
  bottom: 35px;
}

.contactUs-bottomIcon img {
  width: 150px;
}

.contactUs-wrapper h1 {
  color: var(--white, #f8fbfc);
  font-family: "Montserrat" !important;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 160%; */
  margin-left: 20px !important;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.contactUs-wrapper {
  display: flex;
  align-items: center;
  /* margin-bottom: 50px; */
}

.contactUs-btn {
  display: flex;
  justify-content: end;
  padding: 150px 0px 80px 0px;
}

.contactUs-btn button {
  outline: none;
  border: none;
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  color: #fff;
  box-shadow: 0px 16px 56px 0px rgba(69, 170, 240, 0.2);
}

.contactUs-btn a {
  text-decoration: none !important;
}

.contactUs-btn a:hover {
  text-decoration: none !important;
}

.owner-btn {
  display: flex;
  justify-content: center;
}

.owner-btn button {
  outline: none;
  border: none;
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  color: #fff;
  box-shadow: 0px 16px 56px 0px rgba(69, 170, 240, 0.2);
}

.owner-btn a {
  text-decoration: none !important;
}

.owner-btn a:hover {
  text-decoration: none !important;
}

.social-icons {
  gap: 15px;
  display: flex;
  color: #45aaf0;
}

.social-wrapper p {
  margin: 20px 0px;
}

.social-content-wrapper p {
  color: #fff !important;
  margin: 15px 0px;
  font-size: 15px;
  font-style: normal;
}

.footer-content-wrapper {
  padding-bottom: 50px;
}

.important-links h3 {
  color: var(--white, #f8fbfc);
  font-family: "Montserrat" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}

.important-links ul {
  padding: 0px;
  /* gap: 50px; */
}

.important-links ul li {
  color: var(--white, #f8fbfc);
  font-family: "Montserrat" !important;
  font-size: 14px;
  font-style: normal;
  list-style: none;
  margin-bottom: 8px;
  text-decoration: none !important;
}

.important-links ul li a {
  color: var(--white, #f8fbfc);
  text-decoration: none !important;
}

.news-letter h3 {
  color: var(--white, #f8fbfc);
  font-family: "Montserrat" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}

.news-letter p {
  color: var(--white, #f8fbfc);
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.checkbox-label {
  color: var(--white, #f8fbfc);
  font-family: "Montserrat" !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.copy-right-footer {
  background: #000;
  padding: 20px 0px 10px 0px;
}

.copy-right-footer p {
  color: #fff;
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  padding: 0px;
  text-align: center;
}

@media (max-width: 768px) {
  .contactUs-wrapper {
    padding: 15px 0px;
    flex-wrap: wrap;
    gap: 15px;
  }

  .contactUs-wrapper h1 {
    width: 280px;
  }

  .contactUs-btn {
    display: flex;
    justify-content: center;
    padding: 40px 15px;
  }

  .owner-btn {
    display: flex;
    justify-content: center;
    padding: 40px 15px;
  }

  .footer-content-wrapper {
    /* max-width: 296px; */
    /* display: flex; */
    margin-left: 5px;
  }

  .class2 {
    margin-left: 10px;
  }

  .footer-contactUs-topIcon {
    right: 245px;
    top: -35px;
  }

  .contactUs-bottomIcon {
    right: 300px;
    bottom: 53px;
  }

  .contactUs-wrapper img {
    margin-top: 90px;
  }

  .contactUs-wrapper h1 {
    width: 280px;
    margin-top: 20px;
  }
}

@media (max-width: 576px) {
  .footer-contactUs-topIcon {
    right: 5px;
    top: 110px;
  }

  .contactUs-bottomIcon {
    right: 35px;
    bottom: 53px;
  }
}

.footer-short {
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
