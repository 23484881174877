.table-heading h1 {
  color: var(--Black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 10px;
}

.supportTicket-bg {
  border-radius: 12px;
  background: var(--White, #f8fbfc);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.supportTicket-addBtn {
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  padding: 5px 15px;
  outline: none !important;
  border: none !important;
}

.supportTicket-addBtn a {
  color: white !important;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none !important;
}

.supportTicket-addBtn:hover {
  background: #45aaf0;
}

.supportTicket-btnWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px;
}

.supportTicket-btnWrapper h2 {
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.84px;
  margin-left: 10px;
  margin-bottom: 0px !important;
}

.supportTicket-content {
  padding: 30px 0px;
  display: flex;
  justify-content: center;
}

.supportTicket-content p {
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.supportTicket-table-th {
  border-radius: 10px;
  background: #fff;
  color: rgba(5, 15, 21, 0.5);
  background: #45AAF0;
}

.supportTicket-wrapper {
  border-radius: 10px !important;

}

.supportTicket-table-th th {
  color: #FFF;
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (min-width: 767px) {
  .page-container {
    background: #F3F3F5;
    padding: 120px;
  }
}

@media (max-width: 767px) {
  .table-heading h1 {
    color: var(--Black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 15px;
    text-align: center;
  }
}