.updateBoat-addBtn {
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  padding: 5px 15px;
  outline: none !important;
  border: none !important;
}

.updateBoat-addBtn a {
  color: white !important;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.updateBoat-addBtn:hover {
  background: #45aaf0;
}

.updateBoat-btnWrapper {
  display: flex;
  align-items: center;
  padding: 30px 0px;
}

.updateBoat-btnWrapper h2 {
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.84px;
  margin-left: 10px;
  margin-bottom: 0px !important;
}

.updateBoat-detailHeading h2 {
  color: #000;
  font-family: "Montserrat" !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.updateBoat-container {
  padding: 30px 30px;
  background: #f3f3f5;
}

.updateBoat-contentBg {
  border-radius: 12px;
  background: #f8fbfc;
  padding: 40px 20px;
}

.updateBoat-amenitiesBg {
  margin-top: 40px;
  border-radius: 12px;
  background: #f8fbfc;
  padding: 10px 20px;
}

.updateBoat-dropdown {
  outline: none;
  border: none;
  display: block;
  font-size: 14px;
  width: inherit;
  color: #f3f3f5;
}

.updateBoat-dropdown input {
  width: 100%;
  border-radius: 6px;
  font-size: 12px;
}

.updateBoat-dropdown select {
  border-radius: 6px;
  background: #f3f3f5;
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: none;
  width: 100%;
  height: 45px;
  padding: 0px 10px;
}

.updateBoat-name-inputs input {
  border-radius: 6px;
  background: #f3f3f5;
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: none;
}


.updateBoat-inputs input {
  border-radius: 6px;
  background: #f3f3f5;
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: none;
}

.updateBoat-message-input input {
  border-radius: 6px;
  background: #f3f3f5;
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: none;
  padding: 20px 20px 120px 20px;
}

.updateBoat-inputLabel {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.updateBoat-inputLabel span {
  color: red;
}


.updateBoat-status-inputs input {
  border-radius: 6px;
  background: #19B000;
  color: #fff !important;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: none;
}

.updateBoat-status-inputs input::placeholder {
  color: #fff !important;
}



.updateBoat-attachment {
  padding: 35px 0px;
  background: #f3f3f5;
  border-radius: 8px;
}

.updateBoat-attachmentContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.updateBoat-attachmentContent p {
  color: var(--neutrals-4, #777e90);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-top: 10px;
}

.updateBoat-xxtraFeature {
  color: #000;
  font-family: "Montserrat" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 5px;
}

.updateBoat-submitBtn {
  padding-top: 50px;
  padding-bottom: 10px;
}

.updateBoat-submitBtn button {
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  width: 100%;
  outline: none;
  border: none;
  padding: 10px 0px;
  width: 100%;
}

.updateBoat-submitBtn button a {
  color: var(--white, #f8fbfc);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none !important;
}

.updateBoat-amenities h2 {
  color: #000;
  font-family: "Montserrat" !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 20px 0px 10px 0px;
}

.updateBoat-tabs button {
  border-radius: 5px;
  background: none;
  color: #fff;
  border: none;
  outline: none;
}

.updateBoat-transaction-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}

.updateBoat-tabs {
  padding: 10px;
  border-radius: 8px;
  background: #f3f3f5;
  color: #777e90;
  border: none;
  outline: none !important;
  font-size: 14px;
  font-style: normal;
  width: 100%;
  text-align: left;
  font-family: "Montserrat" !important;
}

.options-text {
  color: inherit;
  cursor: pointer;
  font-size: 18px !important;
}

.updateBoat-selected-tab {
  background: #45aaf0 !important;
  border-radius: 10px;
  outline: none !important;
  border: none !important;
  color: #fff !important;
}

.updateBoat-selected-tab p {
  color: #fff !important;
}

.updateBoat-imageGalleryWrpper {
  padding: 35px 0px;
  background: #f3f3f5;
  border-radius: 8px;
}

.updateBoat-imageGallery {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.updateBoat-imageGallery p {
  color: var(--neutrals-4, #777e90);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-top: 10px;
}

.material-icons {
  font-family: 'Material Icons' !important;
}

.ReactImagePickerEditor .place-image .image-holder{
  padding:25px
}