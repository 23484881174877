.dashboard-menuIcon {
  text-align: center;
  margin-bottom: 50px;
}


.dashboard-menuIcon i {
  color: black !important;
}

.dashboard-menuIcon img {
  width: 35px;
}

.owner-dashboardLogo-img {
  padding-bottom: 50px !important;
  padding-top: 20px;
}

.owner-dashboardLogo-img img {
  width: 150px;
}

.sideNav {
  display: flex;
  flex-direction: column !important;
}

.side-nav-icon {
  display: block;
}

.side-nav-icon p {
  font-size: 10px;
  font-weight: bold;
  margin: 0px;
  color: #9d9d9d;
  font-family: "Montserrat" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.54px;
}

.side-nav-icon img {
  width: 28px;
  margin-top: 10px;
}

.dashboard-selected-tab {
  background: #45aaf0 !important;
  border-radius: 10px;
  outline: none !important;
  border: none !important;
  padding: 10px 10px 10px 10px;
}

.dashboard-selected-tab p {
  color: #fff !important;
}

.side-nav-icon p:hover {
  outline: none !important;
  border: none !important;
}

.dashboard-subTitle {
  color: #ffffff;
  font-family: "Montserrat" !important;
  font-size: 13px;
  font-style: normal;
  font-weight: bold !important;
  line-height: normal;
  letter-spacing: 0.6px;
}

.sideNav-Bg {
  box-shadow: 7px 0px 7px -6px rgba(0, 0, 0, 0.75);
  padding-left: 40px !important;
  padding-right: 20px !important;
  display: none;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* font-family: "Poppins", sans-serif; */
  font-size: "Montserrat" !important;
}

.html {
  font-size: 62.5%;
}

.navbar input[type="checkbox"],
.navbar .hamburger-lines-ownerside {
  display: none;
}

.container {
  max-width: 1200px;
  width: 90%;
  margin: auto;
}

.navbar-owner {
  color: #fff;
  background-color: #000;
}


.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 64px; */
}

.menu-items {
  order: 2;
  display: flex;
  flex-direction: column;
  z-index: 99;
  background-color: #000;
  color: #ffffff;
}

.logo {
  order: 1;
  font-size: 1.8rem;
}

.menu-items li,
button {
  list-style: none;
}

.navbar a {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
}

.navbar a:hover {
  color: #0088a9;
}

.navbar .menu-items li {
  font-size: 1rem;
  font-weight: 500;
}

.side-nav-icon {
  margin-bottom: 20px;
}

@media (min-width: 767px) {

  .navbar-owner {
    /* position: fixed; */
    width: 100%;
    overflow-y: scroll;
    height: 100vh;
  }

  .owner-sidebar {
    background-color: #000;
    height: 90vh;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 99;
  }
}

@media (max-width: 767px) {
  .navbar-container input[type="checkbox"]:checked~.logo {
    display: none;
  }

  .navbar-owner {
    color: #fff;
    background-color: #ffffff;
    /* margin-left: -25px; */
  }

  .navbar .menu-items {
    padding-top: 38px;
    background: #000;
    /* height: 100vh; */
    min-width: 250px !important;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    /* margin-right: 320px; */
    padding-left: 20px;
    padding-right: 20px;
    /* margin-top: 45px; */
    transition: transform 0.5s ease-in-out;
    overflow: scroll;
    z-index: 99;
  }

  .mobile-sidebar-container {
    position: relative;
  }

  #sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }

  .mobile-position {
    /* position: absolute;
    top: 0;
    left: 0; */
    /* margin-top: 72px !important; */
    overflow-y: auto;
    height: 100vh;
    position: fixed;
    top: 72px;
    left: 0;
    z-index: 99;
    padding-bottom: 100px;
  }

  .owner-sidebar {
    background-color: #000;
    /* height: 100vh;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 99; */
  }

  .sideNav.showMenu .side-nav-icon {
    display: block;
  }

  .navbar {
    /* background: #f3f3f5 !important; */

  }

  .owner-dashboardLogo-img {
    padding-bottom: 50px !important;
    padding-top: 10px;
    margin-left: 180px;
  }

  .owner-dashboardLogo-img img {
    width: 180px;
  }

  .navbar-container input[type="checkbox"],
  .navbar-container .hamburger-lines-ownerside {
    display: block;
  }

  .navbar-container {
    display: block;
    position: relative;
    height: 64px;
  }

  .navbar-container input[type="checkbox"] {
    position: absolute;
    display: block;
    height: 32px;
    width: 30px;
    top: 20px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .navbar-container .hamburger-lines-ownerside {
    display: block;
    height: 28px;
    width: 35px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .navbar-container .hamburger-lines-ownerside .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    /* background: #fff; */
    color: #000 !important;
    border: 2px solid #000 !important;
  }

  .navbar-container .hamburger-lines-ownerside .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
    color: #000 !important;
    border: 2px solid #000 !important;
  }

  .navbar-container .hamburger-lines-ownerside .line2 {
    transition: transform 0.1s ease-in-out;
    color: #000 !important;
    border: 2px solid #000 !important;
  }

  .navbar-container .hamburger-lines-ownerside .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
    color: #000 !important;
    border: 2px solid #000 !important;
  }

  .logo {
    position: absolute;
    top: 12px;
    right: 15px;
    font-size: 1.7rem;
  }

  .navbar-container input[type="checkbox"]:checked~.menu-items {
    transform: translateX(0);
  }

  .navbar-container input[type="checkbox"]:checked~.hamburger-lines-ownerside .line1 {
    transform: rotate(45deg);
  }

  .navbar-container input[type="checkbox"]:checked~.hamburger-lines-ownerside .line2 {
    transform: scaleY(0);
  }

  .navbar-container input[type="checkbox"]:checked~.hamburger-lines-ownerside .line3 {
    transform: rotate(-45deg);
  }
}



/* Sidebar scroll */
/* Width */
.mobile-position::-webkit-scrollbar,
#sidebar::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.mobile-position::-webkit-scrollbar-track,
#sidebar::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
.mobile-position::-webkit-scrollbar-thumb,
#sidebar::-webkit-scrollbar-thumb {
  background: #8BC53E;
}

/* Handle on hover */
.mobile-position::-webkit-scrollbar-thumb:hover,
#sidebar::-webkit-scrollbar-thumb:hover {
  background: #6c9b2e;
}