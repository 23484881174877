.openTicket-heading {
  padding-top: 20px;
}

.openTicket-heading h1 {
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 22px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.84px;
}

.openTicket-container {
  padding: 30px 30px;
  background: #f3f3f5;
}

.openTicket-contentBg {
  margin-top: 40px;
  border-radius: 12px;
  background: #f8fbfc;
  padding: 40px 20px;
}

.openTicket-dropdown {
  outline: none;
  border: none;
  display: block;
  font-size: 14px;
  width: inherit;
  color: #bbbbbb;
}

.openTicket-dropdown input {
  width: 100%;
  border-radius: 6px;
  font-size: 12px;
}

.openTicket-dropdown select {
  border-radius: 6px;
  background: #f3f3f5;
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: none;
  width: 100%;
  height: 45px;
  padding: 0px 10px;
}

.openTicket-name-inputs input {
  border-radius: 6px;
  background: #e3e3e3;
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  opacity: 0.6;
}

.openTicket-inputs input {
  border-radius: 6px;
  background: #f3f3f5;
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: none;
}

.openTicket-message-input input {
  border-radius: 6px;
  background: #f3f3f5;
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: none;
  padding: 20px 20px 120px 20px;
}

.openTicket-inputLabel {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.openTicket-inputLabel span {
  color: red;
}

.openTicket-attachment {
  padding: 35px 0px;
  background: #f3f3f5;
}

.openTicket-attachmentContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.openTicket-attachmentContent p {
  color: var(--neutrals-4, #777e90);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-top: 10px;
}

.openTicket-attachmentText {
  color: rgba(5, 15, 21, 0.5);
  font-family: "Montserrat" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 5px;
}

.openTicket-submitBtn {
  padding-top: 100px;
  padding-bottom: 10px;
}

.openTicket-submitBtn button {
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  width: 100%;
  outline: none;
  border: none;
  padding: 10px 0px;
  width: 100%;
  text-decoration: none !important;
}

.openTicket-submitBtn button a {
  color: var(--white, #f8fbfc);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none !important;
}