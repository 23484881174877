:root {
  --black: #050f15;
  --black2: #1a1a1a;
}

/* Styles for Large Screens (e.g., desktops) */
@media (min-width: 1025px) {
  .center-heading {
    margin: 70px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .ch-main-heading {
    position: relative;
    padding: 10px 30px 0px 30px;
  }

  .center-heading-top-icon {
    position: absolute;
    right: -10px;
    top: 0px;
  }

  .center-heading-bottom-icon {
    position: absolute;
    right: 20px;
    bottom: 10px;
    z-index: -1;
  }

  .ch-main-heading h1 {
    color: var(--black);
    font-family: "Montserrat" !important;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.88px;
    text-align: center;
    margin-bottom: 10px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
  }

  .ch-main-heading h1 .img1 {
    position: absolute;
    /* z-index: -1; */
    top: -5px;
    right: -30px;
  }
  .ch-main-heading h1 .img2 {
    position: absolute;
    z-index: -1;
  }

  .center-heading p {
    color: var(--black);
    text-align: center;
    font-family: "Montserrat" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }

  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .left-heading {
    margin: 70px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .lh-main-heading {
    position: relative;
    display: flex;
    justify-content: end;
  }

  .lh-main-heading h1 {
    color: var(--black2);
    font-family: "Montserrat" !important;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    margin: 0px 90px;
  }

  .lh-main-heading h1 .img1 {
    position: absolute;
    left: 162px;
    bottom: 20px;
    z-index: -1;
  }

  .lh-main-heading h1 .img2 {
    padding-bottom: 60px;
  }

  .wh-main-heading {
    position: relative;
    display: flex;
    justify-content: end;
  }

  .wh-main-heading h1 {
    color: var(--black2);
    font-family: "Montserrat" !important;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    margin: 0px 90px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
  }

  .wh-main-heading h1 .img1 {
    position: absolute;
    /* z-index: -1; */
    top: -10px;
    right: -30px;
  }
  .wh-main-heading h1 .img2 {
    position: absolute;
    z-index: -1;
  }

  /* .left-heading-top-icon {
        position: absolute;
        right: 315px;
        top: 70px;
    }

    .left-heading-bottom-icon {
        position: absolute;
        right: 315px;
        top: 110px;
        z-index: -1;
    } */

  .left-heading p {
    color: var(--black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    opacity: 0.800000011920929;
    margin: 0px 90px;
  }
}

/* Styles for Medium Screens (e.g., tablets) */
@media (min-width: 768px) and (max-width: 1024px) {
  .center-heading {
    margin: 70px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .ch-main-heading {
    position: relative;
    padding: 10px 30px 0px 30px;
  }

  .center-heading-top-icon {
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .center-heading-bottom-icon {
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: -1;
  }

  .ch-main-heading h1 {
    color: var(--black);
    font-family: "Montserrat" !important;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.88px;
    text-align: center;
    margin-bottom: 10px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
  }

  .ch-main-heading h1 .img1 {
    position: absolute;
    /* z-index: -1; */
    top: -5px;
    right: -30px;
  }
  .ch-main-heading h1 .img2 {
    position: absolute;
    z-index: -1;
  }

  .center-heading p {
    color: var(--black);
    text-align: center;
    font-family: "Montserrat" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }

  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .left-heading {
    margin: 70px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .lh-main-heading {
    position: relative;
    display: flex;
    justify-content: end;
  }

  .lh-main-heading h1 {
    color: var(--black2);
    font-family: "Montserrat" !important;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    margin: 0px 90px;
  }

  .lh-main-heading h1 .img1 {
    position: absolute;
    left: 115px;
    bottom: 25px;
    z-index: -1;
    width: 200px;
  }

  .lh-main-heading h1 .img2 {
    padding-bottom: 60px;
  }

  .wh-main-heading {
    position: relative;
    display: flex;
    justify-content: end;
}

.wh-main-heading h1 {
    color: var(--black2);
    font-family: "Montserrat" !important;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    margin: 0px 90px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
  }

  .wh-main-heading h1 .img1 {
    position: absolute;
    /* z-index: -1; */
    top: -10px;
    right: -30px;
  }
  .wh-main-heading h1 .img2 {
    position: absolute;
    z-index: -1;
  }

  /* .left-heading-top-icon {
        position: absolute;
        right: 315px;
        top: 70px;
    }

    .left-heading-bottom-icon {
        position: absolute;
        right: 315px;
        top: 110px;
        z-index: -1;
    } */

  .left-heading p {
    color: var(--black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    opacity: 0.800000011920929;
    margin: 0px 90px;
  }
}

/* Styles for Small Landscape Screens (e.g., landscape mobile) */
@media (max-width: 768px) and (orientation: landscape) {
  .center-heading {
    margin: 70px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .ch-main-heading {
    position: relative;
    padding: 10px 30px 0px 30px;
  }

  .center-heading-top-icon {
    position: absolute;
    right: -5px;
    top: -5px;
  }

  .center-heading-bottom-icon {
    position: absolute;
    right: 20px;
    bottom: 5px;
    z-index: -1;
  }

  .ch-main-heading h1 {
    color: var(--black);
    font-family: "Montserrat" !important;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.88px;
    text-align: center;
    margin-bottom: 10px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
  }

  .ch-main-heading h1 .img1 {
    position: absolute;
    /* z-index: -1; */
    top: -5px;
    right: -30px;
  }
  .ch-main-heading h1 .img2 {
    position: absolute;
    z-index: -1;
  }

  .center-heading p {
    color: var(--black);
    text-align: center;
    font-family: "Montserrat" !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }
  .left-container{
    display: flex;
    justify-content: start;
    padding-left: 0px !important;
  }
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .left-heading {
    margin: 70px 0px;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
  }

  .lh-main-heading {
    position: relative;
    display: flex;
    justify-content: end;
  }

  .lh-main-heading h1 {
    color: var(--black2);
    font-family: "Montserrat" !important;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    margin: 0px 90px;
  }

  .lh-main-heading h1 .img1 {
    position: absolute;
    left: 210px;
    bottom: 5px;
    z-index: -1;
    width: 200px;
  }

  .lh-main-heading h1 .img2 {
    position: absolute;
    right:  75px;
    bottom: -30px;
    /* padding-bottom: 60px; */
  }

  .enjouTrip-heading {
    display: flex;
    flex-direction: column;
    justify-content: start;
     align-items: start;
  } 
  .wh-main-heading {
    position: relative;
    display: flex;
    justify-content: end;
}

.wh-main-heading h1 {
    color: var(--black2);
    font-family: "Montserrat" !important;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    margin: 0px 90px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
  }

  .wh-main-heading h1 .img1 {
    position: absolute;
    /* z-index: -1; */
    top: -10px;
    right: -30px;
  }
  .wh-main-heading h1 .img2 {
    position: absolute;
    z-index: -1;
  }

  /* .left-heading-top-icon {
        position: absolute;
   
    }

    .left-heading-bottom-icon {
        position: absolute;
        right: 315px;
        top: 30px;
        z-index: -1;
    } */

  .left-heading p {
    color: var(--black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    opacity: 0.800000011920929;
    margin: 0px 90px;
  }
}

/* Styles for Small Screens (e.g., portrait mobile) */
@media (max-width: 576px) {
  .center-heading {
    margin: 70px 0px;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
  }

  .ch-main-heading {
    position: relative;
    padding: 0px;
  }

  .center-heading-top-icon {
    position: absolute;
    right: 174px;
    top: 17px;
  }

  .center-heading-bottom-icon {
    position: absolute;
    right: 20px;
    bottom: 0px;
    z-index: -1;
  }
  .center-heading-bottom-icon img{
    /* width: 180px; */
  }
  .ch-main-heading h1 {
    color: var(--black);
    font-family: "Montserrat" !important;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.88px;
    text-align: start;
    margin-bottom: 10px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
  }

  .ch-main-heading h1 .img1 {
    position: absolute;
    /* z-index: -1; */
    top: -10px;
    right: -30px;
  }
  .ch-main-heading h1 .img2 {
    position: absolute;
    z-index: -1;
  }

  .center-heading p {
    color: var(--black);
    text-align: start;
    font-family: "Montserrat" !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    
  }

  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .left-heading {
    margin: 70px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .lh-main-heading {
    position: relative;
    display: flex;
    justify-content: end;
  }

  .lh-main-heading h1 {
    color: var(--black2);
    font-family: "Montserrat" !important;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    margin: 0px 0px;
  }

  .wh-main-heading {
    position: relative;
    display: flex;
    justify-content: end;
}

.wh-main-heading h1 {
    color: var(--black2);
    font-family: "Montserrat" !important;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    margin: 0px 90px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
  }

  .wh-main-heading h1 .img1 {
    position: absolute;
    /* z-index: -1; */
    top: -10px;
    right: -30px;
  }
  .wh-main-heading h1 .img2 {
    position: absolute;
    z-index: -1;
  }



  .left-heading-top-icon {
    position: absolute;
    /* right: 250px;
        top: 70px; */
  }

  /* .left-heading-bottom-icon {
        position: absolute;
        right: 315px;
        top: 30px;
        z-index: -1;
    }

    .left-heading p {
        color: var(--black, #050f15);
        font-family: "Montserrat" !important;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        opacity: 0.800000011920929;
        margin: 0px 90px;
    } */

  .lh-main-heading h1 .img1 {
    position: absolute;
    left: 85px;
    bottom: 38px;
    z-index: -1;
    width: 200px;
  }

  .lh-main-heading h1 .img2 {
    position: relative;
    /* padding-bottom: 50px; */
    left: 80px !important;
    bottom: 60px;
  }
}
