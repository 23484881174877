.uploadimage-attachment {
  padding: 35px 0px;
  background: #f3f3f5;
  border-radius: 8px;
}

.uploadimage-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.uploadimage-content p {
  color: var(--neutrals-4, #777e90);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-top: 10px;
}

.no-padding {
  padding: 0;
}


.uploadimage-closebtn {
  position: absolute;
  top: 10px;
  right: 2px;
  padding: 0px;
  margin: 0px;
}