ol,
ul {
  padding: 0;
}

.dashboard-menuIcon {
  text-align: center;
  margin-bottom: 50px;
}

.dashboard-menuIcon i {
  color: black !important;
}

.dashboard-menuIcon img {
  width: 35px;
}

.dashboardLogo-img {
  padding: 50px 0px;
}

.dashboardLogo-img img {
  width: 150px;
}

.sideNav {
  display: flex;
  flex-direction: column !important;
}

/* Show the menu links for smaller screens */
@media (max-width: 768px) {
  .userside-nav-icon {
    display: block;
  }

  .sideNav.showMenu .userside-nav-icon {
    display: block;
  }
}

.userside-nav-icon {
  margin-bottom: 30px;
}

.userside-nav-icon p {
  margin: 0px;
  color: #050F15;
  font-family: "Montserrat" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.54px;
  padding-left: 12px !important;
}

.userside-nav-icon img {
  width: 28px;
  margin-top: 10px;
}

.userDashboard-selected-tab {
  background: #45aaf0 !important;
  border-radius: 10px;
  outline: none !important;
  border: none !important;
  padding: 10px 10px 10px 10px;
}

.userDashboard-selected-tab p {
  color: #fff !important;
  padding-left: 0px !important;
}

.userDashboard-selected-tab svg {
  color: #fff !important;
}

.userside-nav-icon p:hover {
  outline: none !important;
  border: none !important;
}

.userside-nav-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userDashboard-subTitle {
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 13px;
  font-style: normal;
  font-weight: bold !important;
  line-height: normal;
  letter-spacing: 0.6px;
}

.sideNav-Bg {
  box-shadow: 7px 0px 7px -6px rgba(0, 0, 0, 0.75);
  padding-left: 40px !important;
  padding-right: 20px !important;
  display: none;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* font-family: "Poppins", sans-serif; */
  font-family: "Montserrat" !important;
}

.html {
  font-size: 62.5%;
}

.user-navbar input[type="checkbox"],
.user-navbar .hamburger-lines {
  display: none;
}

.container {
  max-width: 1200px;
  width: 90%;
  margin: auto;
}

.user-navbar {
  /* position: fixed; */
  width: 100%;
  border-radius: 12px;
  background: var(--White, #f8fbfc);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  padding-top: 50px;

}

.user-navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 64px; */
}

.user-menu-items {
  order: 2;
  display: flex;
  flex-direction: column;
  z-index: 99;
  width: 100%;
}

.logo {
  order: 1;
  font-size: 1.8rem;
}

.user-menu-items li,
button {
  list-style: none;
}

.user-navbar a {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
}

.user-navbar a:hover {
  color: #0088a9;
}

@media (max-width: 768px) {

  .user-navbar-container input[type="checkbox"],
  .user-navbar-container .hamburger-lines {
    display: block;
  }

  .user-navbar-container {
    display: block;
    position: relative;
    height: 64px;
  }

  .user-navbar-container input[type="checkbox"] {
    position: absolute;
    display: block;
    height: 32px;
    width: 30px;
    top: 20px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .user-navbar-container .hamburger-lines {
    display: block;
    height: 28px;
    width: 35px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .user-navbar-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #fff;
  }

  .user-navbar-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
  }

  .user-navbar-container .hamburger-lines .line2 {
    transition: transform 0.1s ease-in-out;
  }

  .user-navbar-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
  }

  .user-navbar .user-menu-items {
    padding-top: 100px;
    background: #24252a;
    height: 100vh;
    max-width: 300px;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    margin-left: -40px;
    padding-left: 40px;
    padding-right: 20px;
    margin-top: 70px;
    transition: transform 0.5s ease-in-out;
    overflow: scroll;
  }

  .user-navbar .user-menu-items li {
    margin-bottom: 1.8rem;
    font-size: 1rem;
    font-weight: 500;
  }

  /* button {
      width: fit-content;
      margin-left: 1rem;
    } */

  .logo {
    position: absolute;
    top: 12px;
    right: 15px;
    font-size: 1.7rem;
  }

  .user-navbar-container input[type="checkbox"]:checked~.user-menu-items {
    transform: translateX(0);
  }

  .user-navbar-container input[type="checkbox"]:checked~.hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .user-navbar-container input[type="checkbox"]:checked~.hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .user-navbar-container input[type="checkbox"]:checked~.hamburger-lines .line3 {
    transform: rotate(-45deg);
  }
}

@media (max-width: 500px) {
  .user-navbar-container input[type="checkbox"]:checked~.logo {
    display: none;
  }
}