.search-menu li {
  position: relative;
}
.search-menu li a {
  display: flex;
  align-items: end;
}
.search-menu li a img {
  margin-right: 5px;
}

.search-menu li:first-child {
  margin-left: 0;
}
.search-menu li.active::after {
  opacity: 1;
}

.search-menu li:first-child {
  margin-left: 0;
}

.search-menu li::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  /* border-bottom: 1px solid var(--secand, #8BC53E); */
  background-color: #8bc53e;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.search-menu li:hover::after {
  opacity: 1;
}
.search-menu {
  list-style: none;
  display: flex;
  margin: 0px;
  padding: 0px !important;
}
.search-menu a {
  color: #000;
  text-decoration: none;
  transition: color 0.3s ease;
}

.search-menu a:hover {
  color: #8bc53e;
}

.navlinks-wrapper {
  margin-bottom: 20px;
}

.show-accommodation button img {
  margin-right: 20px;
}
.show-accommodation button {
  color: #f8fbfc;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  border: none;
  outline: none;
  padding: 16px 0px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  width: 100%;
}

.mainSearch-dropdown {
  border-radius: 8px;
  border: 1px solid rgba(5, 15, 21, 0.2);
}
.mainSearch-input input {
  border-radius: 8px;
  border: 1px solid rgba(5, 15, 21, 0.2);
  background: #fff;
}

.search-engine-wrapper {
  padding: 20px;
  box-shadow: 0px 40px 64px -32px rgba(15, 15, 15, 0.1);
  backdrop-filter: blur(16px);
  border-radius: 0px 0px 20px 20px;
}

.rdrDefinedRangesWrapper {
  display: none !important;
}

.vertical-line {
  content: "";
  border-left: 2px solid gray !important;
  height: 35px;
  margin-left: 5px;
  padding: 0;
}

@media (max-width: 768px) {
  .wrapper {
    gap: 15px;
  }
  .main-links-wrapper {
    display: flex;
    align-items: center;
  }
  .main-links-wrapper img {
    margin-right: 5px;
  }
  .search-menu {
    gap: 10px;
  }
}
