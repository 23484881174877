.bookedProperty-heading {
  margin-bottom: 50px;
  padding-top: 50px;
}

.mainContainer {
  padding: 0px 30px;
}

.bookedProperty-heading h1 {
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.84px;
}

.bookedProperty-heading p {
  color: rgba(5, 15, 21, 0.5);
  font-family: "Montserrat" !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.72px;
}

.bookedProperty-heading p span {
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.6px;
  border-radius: 8px;
  background: #b5e1ff;
  padding: 6px;
  margin-left: 10px;
}

.bookedProperty-headingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mainContainer {
  background: #f3f3f5;
}

.bookedProperty-addBtn {
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  padding: 10px 25px;
  outline: none;
  border: none;
}

.bookedProperty-addBtn a {
  color: white !important;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none !important;
}

.bookedProperty-addBtn:hover {
  background: #45aaf0;
}


.bookedProperty-table-th {
  border-radius: 10px;
  background: #fff;
  color: rgba(5, 15, 21, 0.5);
}

.bookedProperty-table-th th {
  color: rgba(5, 15, 21, 0.5);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.bookedProperty-td-wrapper td {
  color: #050f15;
  font-family: "Montserrat" !important;
  line-height: normal;
  background: #fff;
  border: none;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: normal;
}

.bookedProperty-td-wrapper td a {
  color: #fff !important;
  font-size: 12px;
  text-decoration: none !important;
}

.table th,
.table td {
  padding: 0.98rem;
}


.bookedProperty-view-bg {
  background: #45aaf0 !important;
  display: flex;
  justify-content: center;
  color: white;
  padding: 4px 0px;
  border-radius: 5px;
}