.site-navbar {
  position: relative;
  border-radius: 0px 0px 16px 16px;
  background: var(--black, #050f15);
  color: #fff;
  padding: 20px 0px;
}

.site-menu li {
  position: relative;
}

.site-menu li:first-child {
  margin-left: 0;
}

.site-menu li.active::after {
  opacity: 1;
}

.site-menu li:first-child {
  margin-left: 0;
}

.site-menu li::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  /* border-bottom: 1px solid var(--secand, #8BC53E); */
  background-color: #8bc53e;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.site-menu li:hover::after {
  opacity: 1;
}

.site-navigation {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.nav-contant {
  display: flex;
  align-items: center;
  gap: 10px;
}

.site-menu-links {
  display: flex;
  list-style: none;
  gap: 15px;
  margin-left: 50px;
}

.site-menu {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 0px;
}

.language-dropdown {
  background: none;
}

.language-dropdown select {
  /* padding: 10px; */
  font-size: 16px;
  border: none !important;
  border-radius: 5px;
  background: none !important;
  color: #fff;
}

.language-dropdown select option {
  color: #000 !important;
}

.language-dropdown select:focus {
  outline: none !important;
}

@media (max-width: 980px) {
  .site-menu-links {
    display: flex;
    gap: 10px;
    margin-left: 0px;
    flex-direction: column;
  }

  .site-menu {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-left: 28px !important;
  }

  .navbar-right-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .navbar-right-wrapper button {
    margin-left: 0px;
    width: 100%;
    margin-top: 10px;
  }
}

@media (min-width: 767px) {
  .navbar-right-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .navbar-right-wrapper button {
    border-radius: 8px;
    background: var(--brand, #45aaf0);
    display: flex;
    padding: 12px 18px;
    gap: 10px;
    color: #f8fbfc;
    font-size: 9px;
    font-style: normal;
    font-weight: 700;
    border: none;
    margin-left: 15px;
    outline: none;
  }

  .navbar-right-wrapper a {
    flex-direction: row;
  }

  .floating-btn-container {
    display: none;
  }
}

@media (max-width: 767px) {
  .floating-btn-container {
    display: block;
    position: absolute;
    top: 100%;
  }

  .floating-btn {
    background-color: #212529;
    height: 60px;
    width: 28px;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transform: translateX(-10px);
    cursor: pointer;
    z-index: 99;
    border: 1px solid #fff;
  }

  .floating-btn:hover {
    background-color: #212529;
    transform: scale(1.2);
  }
}
