.boatHistory-heading h1 {
  color: var(--Black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 15px;
}

.boatHistory-bg {
  border-radius: 12px;
  background: var(--White, #f8fbfc);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.boatHistory-title h2 {
  color: #000;
  font-family: "Montserrat" !important;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 25px;
}

.boatHistory-table-th {
  border-radius: 10px;
  background: #fff;
  color: rgba(5, 15, 21, 0.5);
  background-color: #45aaf0;
}

.boatHistory-table-th th {
  color: #fff;
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.boatHistory-td-wrapper td {
  color: #050f15;
  font-family: "Montserrat" !important;
  line-height: normal;
  background: #fff;
  border: none;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: normal;
}

.boatmodal-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  outline: none !important;
}

.boatmodal-inputs input {
  width: 100%;
  border: 1px solid #b8b8b8;
  background: #e7e7e7;
  padding: 15px 20px 50px 15px;
  border-radius: 10px;
  outline: none !important;
  border: none !important;
}

.boatmodal-inputs {
  padding: 22px;
}

@media (min-width: 767px) {
  .page-container {
    background: #F3F3F5;
    padding: 120px;
  }
}

@media (max-width: 767px) {
  .boatHistory-heading h1 {
    color: var(--Black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 15px;
    text-align: center;
  }
}