.accomodationList-left-bg {
  padding: 20px;
  border-radius: 16px;
  border: 1px solid rgba(5, 15, 21, 0.1);
  background: #f8fbfc;
  margin-top: 30px;
}

.accomodationList-right-bg {
  padding: 20px;
  border-radius: 16px;
  border: 1px solid rgba(5, 15, 21, 0.1);
  background: #fff;
  margin-top: 30px;
}

.accomodation-nameWrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.accomodation-card-location h1 {
  font-family: "Montserrat" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 2px;
}

.accomdation-discount {
  font-family: "Montserrat" !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  border-radius: 6px;
  padding: 4px 8px;
  background: #7367f0;
  /* background:  #febb02 !important ; */
  color: #fff;
}

.accomdation-discount-sole {
  font-family: "Montserrat" !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  border-radius: 6px;
  padding: 4px 8px;
  background: #febb02 !important;
  color: #fff;
}

.accomdation-discount-star {
  font-family: "Montserrat" !important;
  font-size: 18px;
  /* font-style: normal; */
  /* font-weight: 700; */
  border-radius: 25px;
  padding: 4px 4px;
  /* background:  #ffbb00 !important ; */
  /* color: #fff; */
}

.accomdation-discount-wrapper {
  display: flex;
  gap: 15px;
}

.accomodation-card-location p {
  margin-bottom: 10px;
}

.accomodation-card-location p span {
  color: rgba(5, 15, 21, 0.5);
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.accomodation-card-Night h3 {
  color: var(--secand, #8bc53e);
  font-family: "Montserrat" !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.56px;
  margin-bottom: 0px;
}

.accomodation-card-Night h3 span {
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.accomodation-card-Night p {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.5;
}

.accomodation-card-Night {
  /* display: flex; */
  /* justify-content: end;
  align-items: end; */
  /* flex-direction: column; */
  text-align: left;
}

.accomodation-card-night-wrapper {
  display: flex;
  justify-content: end;
}

.accomodation-card-deatil-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* baseline; */
  /* margin-top: 30px; */
  margin-bottom: 0px;
}

.accomodation-card-deatil-button p {
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: #050f15;
  font-weight: 600;
  opacity: 0.6;
}

.accomodation-card-deatil-button p span {
  font-family: "Montserrat" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #050f15;
  font-weight: 600;
  opacity: 0.8;
  margin-left: 5px;
}

.accomodation-card-deatil-button button {
  padding: 12px 24px;
  font-weight: 500;
  /* margin-right: 10px; */
  color: #fff;
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  outline: none;
  border: none;
}

.accomodationPage-fastBoatCards {
  position: absolute;
  width: 100%;
  z-index: 1;
  /* bottom: 0; */
}

.accomodationPage-footer {
  position: relative;

  /* bottom: -280px; */
}

.accomodationCraousal .carousel .thumbs-wrapper {
  margin: 0px;
}

.filter-box {
  display: flex;
  justify-content: space-between;
}

.bg-rating {
  background-color: #f3f3f5;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.bg-selected-rating {
  background-color: #f3f3f5;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  background-color: #8bc53e;
}

.slider-wrapping {
  padding-left: 10px !important;
}
.accomodation-card-image img {
  height: 100% !important;
}

@media (max-width: 767px) {
  .accomodation-nameWrapper-bagde {
    display: flex;
    /* justify-content: space-between; */
    align-items: baseline;
    flex-direction: column;
  }

  .accomodation-card-deatil-button .review {
    display: block;
  }

  /* .accomodation-property-type {
    display: none;
  } */
}
