.navbar-userIcon img {
  margin-right: 30px;
  margin-left: 20px;
}

@media (min-width: 767px) {
  .narbar-wrapper {
    display: flex;
    justify-content: end;
    align-items: center;
    background: #f3f3f5;
    padding: 12px 10px;
  }

  .search-input {
    width: 350px;
    margin-right: 30px;
  }

  .search-input input {
    border-radius: 12px;
    background: #EEE;
    color: #A1A1A1;
    font-family: "Montserrat" !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
  }
}

@media (max-width: 767px) {
  .narbar-wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    background: #f3f3f5;
    padding: 12px 10px;
  }

  .navbar-userIcon {
    display: none;
  }

  /* .search-input {
    width: 350px;
    margin-right: 30px;
  } */
  .search-input input {
    border-radius: 12px;
    background: #EEE;
    color: #A1A1A1;
    font-family: "Montserrat" !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
  }
}