.privacyPolicy-heading h1 {
  color: var(--Black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 60px;
  text-align: center;
}
 


.privacyPolicy-text {
  padding: 30px 0px;
}

.privacyPolicy-text h2 {
  color: #1A1A1A;
  text-align: justify;
  font-family: "Montserrat" !important;
  font-size: 25px;
  font-style: normal;
  font-weight: 900;
}

.privacyPolicy-text p {
  color: #1A1A1A;
  font-family: "Montserrat" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 0px;
}



@media (min-width: 767px) {
  .privacyPolicy-Bg {
    padding: 80px 250px;
  }
}

@media (max-width: 767px) {
  .privacyPolicy-Bg {
    padding: 80px 50px;
  }
}