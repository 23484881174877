input[type="date"]
{
    display:block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.2em;
}
.boatDetail-left-bg {
  padding: 40px;
  border-radius: 16px;
  border: 1px solid rgba(5, 15, 21, 0.1);
}

.boatDetail-right-bg {
  padding: 40px;
  border-radius: 16px;
  border: 1px solid rgba(5, 15, 21, 0.1);
  background: #f8fbfc;
}

.boat-detail-img img {
  width: 100%;
  border-radius: 10px !important;
  cursor: pointer;
}

.boatDetail-location {
  font-family: "Montserrat" !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
}

.boatDetail-location-icon p span {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  opacity: 0.8;
}

.boat-wishlist-wrapper {
  display: flex;
  align-items: end;
}

.boat-wishlist-wrapper p {
  margin-left: 7px;
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
}

.boatDetail-location-wrapper {
  display: flex;
  justify-content: space-between;
}

.about-boat-info {
  background-color: #f8fbfc;
  border-radius: 8px;
  padding: 10px 0px 10px 10px;
}

.about-boat-info h4 {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-top: 8px;
}

.about-boat-info p {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.699999988079071;
  margin-bottom: 0px;
}

.boatThings-heading {
  font-family: "Montserrat" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.boatThings-content {
  margin-left: 15px;
}

.boatThings-content h2 {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  /* margin-bottom: 0px; */
}

.boatThings-content p {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.699999988079071;
  margin-bottom: 0px;
}

.boatThings-content-wrapper {
  display: flex;
  align-items: start;
  background-color: #f8fbfc;
  border-radius: 8px;
  padding: 10px 0px 10px 10px;
}

.boatDetail-amenities-heading {
  font-family: "Montserrat" !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.boatAmenities--feature-wrapper {
  display: flex;
  align-items: start;
}

.boatAmenities--feature p {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.800000011920929;
  margin-bottom: 0px;
  margin-left: 8px;
}

.boatDetail-discription p {
  color: #1a1a1a;
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.800000011920929;
}

.boatDetail-discription p span {
  color: #8bc53e;
}

.boatDetail-ocation img {
  width: 100%;
}

.host-name {
  margin-left: 15px;
}

.host-name h2 {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.host-name p span {
  font-family: "Montserrat" !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.799999988079071;
  margin-left: 5px;
}

.host-name-wrapper {
  display: flex;
  align-items: start;
}

.host-discription p {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.800000011920929;
  margin-top: 20px;
}

.boatHost-feature-wrapper {
  display: flex;
  align-items: start;
}

.boatHost-feature p {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.800000011920929;
  margin-bottom: 0px;
  margin-left: 8px;
}

.boatDetail-ranking-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.boatDetail-ranking {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.boatDetail-ranking h3 {
  color: var(--secand, #8bc53e);
  font-family: "Montserrat" !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.56px;
}

.boatDetail-ranking h3 span {
  color: #000;
  font-size: 16px;
  font-style: normal;
}

.boatDetail-reviews p span a {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
}

.boatDetail-date-wrapper {
  padding: 8px;
  border-radius: 16px;
  display: flex;
  align-items: start;
  background-color: #f3f3f5;
  border-radius: 8px;
  /* padding: 10px 0px 10px 10px; */
}

.boatDate-content {
  /* margin-left: 10px; */
  width: 100%;
}

.boatDate-content input {
  border: none;
  outline: none;
  text-transform: uppercase;
  width: 100% !important;
  background: none;
}

.boatDate-content h2 {
  font-family: "Montserrat" !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  /* margin-bottom: 0px; */
}

.boatDate-content p {
  font-family: "Montserrat" !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.699999988079071;
  margin-bottom: 0px;
}

.boatRent-perNight {
  display: flex;
  justify-content: space-between;
}

.boatRent-perNight p {
  font-family: "Montserrat" !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

.boatRent-perNight-total {
  display: flex;
  justify-content: space-between;
}

.boatRent-perNight-total p {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.boatDetail-reserver button {
  padding: 10px 10px;
  gap: 10px;
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  color: #fff;
  border: none;
  outline: none;
  width: 100%;
  font-weight: 600;
  margin-top: 15px;
}

.boatDetail-reserver p {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.6000000238418579;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
}

.boatDetailPage-fastBoatCards {
  position: absolute;
  z-index: 1;
  width: 100%;

  /* bottom: 0; */
}

.boatDetailPage-footer {
  position: relative;
  /* bottom: -280px; */
}

.similar-boat-heading {
  margin-top: 130px;
}

.similar-boat-heading h1 {
  font-family: "Montserrat" !important;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
}

.gmap_canvas {
  width: 100%;
}

#gmap_canvas {
  width: 100%;
  height: 300px;
}

.boat-image-carousal {
  width: 60% !important;
  margin: auto !important;
  background: #fff !important;
  border-radius: 10px !important;
  outline: none !important;
  padding: 20px 0px !important;
  outline: none !important;
}

.boat-image-carousal .carousel-inner .carousel-item {
  height: 450px !important;
  background-size: contain !important;
}

.carousel-control-prev-boat {
  position: absolute;
  top: 50%;
  left: 30px !important ;
  /* padding-top: 9px !important; */
  color: #fff;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: none !important;
  outline: none !important;
  font-size: 20px;
  font-weight: bold !important;
}
.carousel-control-next-boat {
  position: absolute;
  right: 30px !important ;
  top: 50%;
  color: #fff;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: none !important;
  outline: none !important;
  font-size: 20px;
  font-weight: bold !important;
}

.carousel-control-prev-boat,
.carousel-control-next-boat {
  background: #45aaf0 !important;
  opacity: 4.5 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boat-image-carousal-modal {
  margin-top: 110px !important;
}
.boat-image-carousal .carousel-inner .carousel-item {
  height: 450px !important;
  background-size: contain !important;
}

@media (min-width: 767px) {
  .boat-image-carousal {
    width: 60% !important;
    margin: auto !important;
    background: #fff !important;
    border-radius: 10px !important;
    outline: none !important;
    padding: 20px 0px !important;
    outline: none !important;
  }
}

@media (max-width: 767px) {
  .boat-detail-carouser-div img {
    height: 60vh !important;
  }
  .boat-image-carousal {
    padding-bottom: 0px !important;
  }
  .boat-image-carousal-modal {
    margin-top: 180px !important;
  }

  .boat-image-carousal {
    width: 100% !important;
    padding: 0px 20px !important;
    background: none !important;
    outline: none !important;
  }

  .boat-image-carousal .carousel-inner .carousel-item {
    min-height: 450px !important;
    background-size: cover !important;
  }
}
