.changePassword-bg {
  border-radius: 12px;
  background: var(--White, #f8fbfc);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.table-heading h1 {
  color: var(--Black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 10px;
}


.changePassword-name-inputs input {
  border-radius: 6px;
  background: #e3e3e3;
  color: #050f15;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  opacity: 0.6;
}

.changePassword-inputLabel {
  color: var(--black, #050f15);
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.changePassword-submitBtn {
  padding-top: 100px;
  padding-bottom: 10px;
}

.changePassword-submitBtn button {
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  width: 100%;
  outline: none;
  border: none;
  padding: 10px 0px;
  width: 100%;
}

.changePassword-submitBtn button a {
  color: var(--white, #f8fbfc);
  text-align: center;
  font-family: "Montserrat" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none !important;
}

@media (min-width: 767px) {
  .page-container {
    background: #F3F3F5;
    padding: 120px;
  }
}

@media (max-width: 767px) {
  .table-heading h1 {
    color: var(--Black, #050f15);
    font-family: "Montserrat" !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 15px;
    text-align: center;
  }
}