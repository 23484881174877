.blog-page-heading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #f3f3f5;
  align-items: center;
  padding: 50px 0px;
}

.blog-page-heading p span {
  color: #8bc53e;
}

.blog-hotelName p span {
  color: #8bc53e;
  border-radius: 6px;
  background: rgba(139, 197, 62, 0.1);
  padding: 6px 12px;
}

.blog-hotelName p {
  font-family: "Montserrat" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.7;
}

.blog-hotelName-wrapper {
  display: flex;
  justify-content: space-between;
}

.blog-card-content h1 {
  font-family: "Montserrat" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.blog-card-content p {
  font-family: "Montserrat" !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.7;
}

.blog-deatil-button button {
  padding: 12px 24px;
  font-weight: bold;
  margin-right: 10px;
  color: #fff;
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  outline: none;
  border: none;
}

.blogPage-fastBoatCards {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.blogPage-footer {
  position: relative;
  /* bottom: -280px; */
}

.img {
  height: 285px;
  width: 419px;
}


@media (max-width: 768px) {
  .blogpage-maintitle {
    display: flex;
    justify-content: start;
    flex-direction: column;
    background: #f3f3f5;
    align-items: start;
    padding: 50px 0px;
    font-size: 25px !important;
    padding-left: 30px !important;
  }
  .blogpage-mainsubtitle {
    text-align: start !important;
    font-size: 25px !important;
    padding-left: 30px !important;
    margin-top: 10px !important;
  }
}

@media (max-width: 567px) {
 .blogpage-maintitle{
    display: flex;
    justify-content: start;
    flex-direction: column;
    background: #f3f3f5;
    align-items: start;
    padding: 50px 0px;
    font-size: 25px !important;
    padding-left: 30px !important;
  }
  .blogpage-mainsubtitle {
    text-align: start !important;
    font-size: 25px !important;
    padding-left: 30px !important;
    margin-top: 10px !important;
  }
}
